

export interface TradingPartner {
  id: string;
  name: string;
  accountId: string;
}

export interface MessageType {
  transactionType: string;
}

export interface TradingPartnerUsers {
  id: string;
  name: string;
  email: string;
}

export interface ListMessageTypesPayload {
  excludeNonIntegratedTransactionTypes?: boolean;
}
