import { Injectable } from '@angular/core';

import { BehaviorSubject, map, Observable, switchMap, tap } from 'rxjs';

import { plainToInstance } from 'class-transformer';

import { UserWithPartner, UserWithPermissions } from '@shared/adapters';

import { IUser, IUserWithPartner, IUserWithPermissions, ListUsersPayload } from '../../models';
import { UsersApiService } from '../../services';

@Injectable({ providedIn: 'root' })
export class UsersService {
  get user$(): Observable<UserWithPermissions> {
    return this.#user$.asObservable();
  }

  get userList$(): Observable<UserWithPartner[]> {
    return this.#userList$.asObservable();
  }

  readonly #user$ = new BehaviorSubject<UserWithPermissions>({} as UserWithPermissions);
  readonly #userList$ = new BehaviorSubject<UserWithPartner[]>([]);

  constructor(private usersApiService: UsersApiService) {}

  getById(userId: string): Observable<IUserWithPermissions> {
    return this.usersApiService.get(userId).pipe(
      map((user) => plainToInstance(UserWithPermissions, user)),
      tap((user) => this.#user$.next(user)),
    );
  }

  getAll(payload?: ListUsersPayload): Observable<IUserWithPartner[]> {
    return this.usersApiService.list(payload).pipe(
      map((users) => plainToInstance(UserWithPartner, users)),
      tap((users) => this.#userList$.next(users)),
    );
  }

  create(payload: Partial<IUser>): Observable<IUserWithPartner[]> {
    return this.usersApiService.create(payload).pipe(switchMap(() => this.getAll()));
  }

  delete(userId: string): Observable<IUserWithPartner[]> {
    return this.usersApiService.delete(userId).pipe(switchMap(() => this.getAll()));
  }

  reSendInvite(userId: string): Observable<IUser[]> {
    return this.usersApiService.reSendInvite(userId);
  }

  attachGroups(userId: string, payload: string[]): Observable<IUserWithPermissions> {
    return this.usersApiService.addGroups(userId, payload).pipe(switchMap(() => this.getById(userId)));
  }

  detachGroup(userId: string, groupId: string): Observable<IUserWithPermissions> {
    return this.usersApiService.detachGroup(userId, groupId).pipe(switchMap(() => this.getById(userId)));
  }
}
