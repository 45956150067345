export enum Permission {
  USERS_CREATE = 'users:create',
  USERS_READ = 'users:read',
  USERS_UPDATE = 'users:update',
  USERS_DELETE = 'users:delete',
  GROUPS_CREATE = 'groups:create',
  GROUPS_READ = 'groups:read',
  GROUPS_UPDATE = 'groups:update',
  GROUPS_DELETE = 'groups:delete',
  ROLES_CREATE = 'roles:create',
  ROLES_READ = 'roles:read',
  ROLES_UPDATE = 'roles:update',
  ROLES_DELETE = 'roles:delete',
  ALERTS_CREATE = 'alerts:create',
  ALERTS_READ = 'alerts:read',
  ALERTS_DELETE = 'alerts:delete',
  ALERTS_UPDATE = 'alerts:update',
  AUDIT_LOG_READ = 'audit-log:read',
  TRANSACTIONS_READ = 'transactions:read',
  DATA_COLLECTION_READ = 'data-collection:read',
  WAREHOUSE_CREATE = 'warehouse:create',
  WAREHOUSE_READ = 'warehouse:read',
  WAREHOUSE_UPDATE = 'warehouse:update',
  WAREHOUSE_DELETE = 'warehouse:delete',
  TEMPLATE_READ = 'template:read',
}
