import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Actor, Organisation } from '@core/models';
import { ActorService } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class CidGuard {
  constructor(
    private actorService: ActorService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const cid: string | null = route.queryParamMap.get('cid');

    // No cid in most of the links
    if (!cid) {
      return true;
    }

    // If cid is present, check access to that cid organisation
    const actor: Actor = this.actorService.get();
    const hasAccess: boolean = actor?.profile?.organisations.some((org: Organisation) => org.id === cid);
    if (!hasAccess) {
      this.router.navigate(['/dashboard']);
    }

    const currentCid: string | null = sessionStorage.getItem('cid');
    // User has access and needs to change work area
    if (cid !== currentCid) {
      this.setNewCid(cid);
      return false;
    }

    // User has access and does not need to change work area
    return true;
  }

  private setNewCid(cid: string): void {
    sessionStorage.setItem('cid', cid);
    window.location.reload();
  }
}
