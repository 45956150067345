import { AlertKeyCondition } from '../enums';
import { SelectOption } from '../models';

export interface SimpleRecipient {
  type: string;
  value: string;
  name?: string;
  email?: string;
}

export interface RecipientItem extends SimpleRecipient {
  label: string;
  selected?: boolean;
}

export interface AlertKeys {
  key: string;
  name: string;
}

export interface AlertEvent {
  key: string;
  name: string;
  desc?: string;
  keys?: AlertKeys[];
}

export interface IAlert {
  id: string;
  name: string;
  event: string;
  conditions: SelectOption<AlertKeyCondition>[];
  recipients: SimpleRecipient[];
  createdAt: string;
  updatedAt: string | null;
}
