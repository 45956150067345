export const environment = {
  production: false,
  networkIds: {
    1: 'https://goerli.etherscan.io/tx/', //ethereum(1)
    2: 'https://sepolia.arbiscan.io/tx/', //arbitrum(2)
    3: '' //@TODO add fabric tx receipt link for dev
  },
  auth0: {
    domain: 'https://dev2.login.veratrak.com',
    audience: 'hub-dev2.eu.auth0.com',
    clientId:'K4Fs458KCkD6NDfEXUVH2LIg4l5A7tNN'
  },
  vapid: 'BFPVeUV_hMp-Pe4fv7IUfpQSoQrdMy2zaSjzQRsUEPezTusdsldFSnBJrnGR4-61b8rV2Y9d5bioTMw1rc6gshY',
  apiHost: 'https://api.dev2.hub.veratrak.com',
  socketHost: 'wss://socket.dev2.hub.veratrak.com',
  jsonCrackHost: 'https://jsoncrack.dev2.hub.veratrak.com',
  jsoncrack: 'https://jsoncrack.dev2.hub.veratrak.com/widget',
  gtm: 'GTM-5NSJ6M3N'
};
