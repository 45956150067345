export interface Bell {
  id: string;
  message: string;
  meta: never;
  read: number;
  archived: number;
  createdAt: Date;
}

export interface BellNotifications {
  new: number;
  notifications: Bell[];
}
