import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { first, Observable } from 'rxjs';

import { environment } from '@src/environments/environment';

import { AcceptRegistration, UserRegistration } from '../models';
import { SnackbarService } from '../services';

const ENDPOINT = 'auth';

@Injectable({ providedIn: 'root' })
export class RegisterService {
  constructor(
    private http: HttpClient,
    private snack: SnackbarService,
  ) {}

  getUserRegistrationInfo(token: string): Observable<UserRegistration> {
    return this.http.get<UserRegistration>(`${environment.apiHost}/${ENDPOINT}/register?token=${token}`).pipe(first());
  }

  setPassword(token: string, payload: AcceptRegistration): Observable<{ status: number }> {
    return this.http
      .put<{ status: number }>(`${environment.apiHost}/${ENDPOINT}/register?token=${token}`, payload)
      .pipe(first(), this.snack.operator());
  }

  accept(token: string): Observable<{ status: number }> {
    return this.http
      .put<{ status: number }>(`${environment.apiHost}/${ENDPOINT}/accept?token=${token}`, null)
      .pipe(first(), this.snack.operator());
  }
}
