import { Injectable } from '@angular/core';

import { first, map, Observable } from 'rxjs';

import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { ActorQuery } from './actor.query';
import { ActorStore } from './actor.store';
import { ActorLoaderKey } from '../../enums';
import { Actor } from '../../models';
import { ApiService, LoadingService, SnackbarService } from '../../services';

const ENDPOINT = 'auth/userinfo';

@Injectable({ providedIn: 'root' })
export class ActorService {
  constructor(
    private api: ApiService,
    private query: ActorQuery,
    private store: ActorStore,
    private loader: LoadingService,
    private snack: SnackbarService,
    private gtm: GoogleTagManagerService,
  ) {}

  getUserInfo(): Observable<void> {
    return this.api.get<Actor>(`${ENDPOINT}`).pipe(
      first(),
      map((actor: Actor) => {
        this.store.update(actor);
        void this.gtm.pushTag({
          event: 'profile',
          id: actor.id,
          name: actor.profile.name,
          email: actor.profile.email,
          user_hash: actor.intercom,
          hub_workarea: actor.currentOrg.name,
          hub_user_type: actor.profile.type,
          hub_trading_partner: actor.profile.partner?.name,
        });
      }),
    );
  }

  get(): Actor {
    return this.query.getValue();
  }

  select(): Observable<Actor> {
    return this.query.select();
  }

  update(name: string, initials: string, bgColor: string): Observable<void> {
    return this.api
      .patch<void, { name: string; initials: string; bgColor: string }>(`${ENDPOINT}`, { name, initials, bgColor })
      .pipe(
        first(),
        map((): void => {
          const profile = this.get().profile;

          this.store.update({ profile: { ...profile, name, avatar: { initials, bgColor } } } as Actor);
        }),
        this.loader.set(ActorLoaderKey.USER_SAVE),
        this.snack.operator('Profile updated'),
      );
  }
}
