import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Weight } from '@core/constants/uom.constant';
import { MeasurementSystem, MeasurementSystemCategory } from '@core/enums/uom.enum';
import { atLeastOneFieldValidator } from '@src/app/shared/validators/at-least-one.validator';

@Component({
  selector: 'app-warehouse1',
  templateUrl: './warehouse1.component.html',
})
export class Warehouse1Component implements OnInit {
  @Input() system!: MeasurementSystem;
  @Input() disabled?: boolean;
  @Output() onChanges = new EventEmitter<FormGroup>();

  form = this.fb.group({
    ['Cargo Weight ambient']: [null, [Validators.pattern(/[1-9]\d*/)]],
    ['UOM Cargo Weight ambient']: null,
    ['Cargo Weight cold chain']: [null, [Validators.pattern(/[1-9]\d*/)]],
    ['UOM Cargo Weight cold chain']: null,
    ['AbbVie Warehouse emission']: [null, [Validators.required, Validators.pattern(/[1-9]\d*/)]],
  }, { validators: atLeastOneFieldValidator(['Cargo Weight ambient', 'Cargo Weight cold chain']) });

  protected readonly MeasurementSystemCategory: typeof MeasurementSystemCategory = MeasurementSystemCategory;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form.patchValue({
      ['UOM Cargo Weight ambient']: Weight[this.system].default,
      ['UOM Cargo Weight cold chain']: Weight[this.system].default,
    });

    if (this.disabled) {
      this.form.get('Cargo Weight ambient')?.disable();
      this.form.get('Cargo Weight cold chain')?.disable();
      this.form.get('AbbVie Warehouse emission')?.disable();
    }

    this.form.valueChanges.subscribe(() => this.onChanges.emit(this.form));
  }
}
