import { InviteTypes } from '@core/enums/register.enum';

interface RegistrationOrganisation {
  name: string;
  logo: string | null;
}

export interface UserRegistration {
  email: string;
  organisation: RegistrationOrganisation;
  inviteType: InviteTypes;
}

export interface AcceptRegistration {
  name: string;
  password: string;
  conditionsRead: boolean;
}
