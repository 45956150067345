import { Exclude, Expose, Transform } from 'class-transformer';

import { EmissionDashboardTotals } from '@core/models';

@Exclude()
export class DashboardTotalsMetrics implements EmissionDashboardTotals {
  @Expose()
  @Transform(({ value = 0 }) => Math.round(value))
    total!: number;

  @Expose()
  @Transform(({ value = 0 }) => Math.round(value))
    warehouse!: number;

  @Expose()
  @Transform(({ value = 0 }) => Math.round(value))
    transport!: number;
}
