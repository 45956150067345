import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { LoadingService } from '../loading';
import { SnackbarService } from '../snackbar.service';
import { Observable, first } from 'rxjs';
import {
  DataCollectionMetricsParams,
  EmissionDashboardMethods,
  EmissionDashboardTotals,
  EmissionsGuestResponse,
  ReportedWarehouses,
  TasksResponse,
} from '@core/models';
import { DashboardLoaderKey, DataCollectionLoaderKey } from '@core/enums';
import { HttpParams } from '@angular/common/http';

const ENDPOINT = 'v1/dashboard';

@Injectable({ providedIn: 'root' })
export class DashboardApiService {
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  getGuestEmissions(): Observable<EmissionsGuestResponse> {
    return this.api
      .get<EmissionsGuestResponse>(`${ENDPOINT}/emissions`)
      .pipe(first(), this.loader.set(DashboardLoaderKey.GET_GUEST_EMISSIONS), this.snack.operator());
  }

  getTasks(): Observable<TasksResponse> {
    return this.api
      .get<TasksResponse>(`${ENDPOINT}/tasks`)
      .pipe(first(), this.loader.set(DashboardLoaderKey.GET_TASKS), this.snack.operator());
  }

  getInternalChart(options: DataCollectionMetricsParams): Observable<ReportedWarehouses> {
    const data = { ...options };
    const params = new HttpParams({ fromObject: data });

    return this.api
      .get<ReportedWarehouses>(`${ENDPOINT}/emissions/chart`, { params })
      .pipe(first(), this.loader.set(DataCollectionLoaderKey.GET_REPORTING_METRICS), this.snack.operator());
  }

  getInternalTotals(options: DataCollectionMetricsParams): Observable<EmissionDashboardTotals> {
    const data = { ...options };
    const params = new HttpParams({ fromObject: data });

    return this.api
      .get<EmissionDashboardTotals>(`${ENDPOINT}/emissions/totals`, { params })
      .pipe(first(), this.loader.set(DataCollectionLoaderKey.GET_TOTALS_METRICS), this.snack.operator());
  }

  getInternalMethods(options: DataCollectionMetricsParams): Observable<EmissionDashboardMethods> {
    const data = { ...options };
    const params = new HttpParams({ fromObject: data });

    return this.api
      .get<EmissionDashboardMethods>(`${ENDPOINT}/emissions/methods`, { params })
      .pipe(first(), this.loader.set(DataCollectionLoaderKey.GET_METHODS_METRICS), this.snack.operator());
  }
}
