import { Injectable } from '@angular/core';
import { SelectOption } from '@core/models';
import { Template, TemplateCategory, TemplateId } from '@core/models/template';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { TemplatesApiService } from './templates-api.service';

@Injectable({ providedIn: 'root' })
export class TemplatesService {
  get templates$(): Observable<Template[]> {
    return this.#templates$.asObservable();
  }

  get transportTemplateList$(): Observable<SelectOption<TemplateId>[]> {
    return this.ensureInitialized().pipe(
      map(() => this.getTemplateList(this.#templates$.value, TemplateCategory.TRANSPORT))
    );
  }

  get warehouseTemplateList$(): Observable<SelectOption<TemplateId>[]> {
    return this.ensureInitialized().pipe(
      map(() => this.getTemplateList(this.#templates$.value, TemplateCategory.WAREHOUSE))
    );
  }

  readonly #templates$ = new BehaviorSubject<Template[]>([]);
  #initialized = false;

  constructor(private templatesApiService: TemplatesApiService) {}

  private ensureInitialized(): Observable<void> {
    if (this.#initialized) {
      return new BehaviorSubject<void>(undefined).asObservable();
    }

    return this.templatesApiService.getTemplates().pipe(
      tap(templates => {
        this.#templates$.next(templates);
        this.#initialized = true;
      }),
      map(() => undefined)
    );
  }

  getTemplates(): Observable<Template[]> {
    return this.ensureInitialized().pipe(map(() => this.#templates$.value));
  }

  getTemplateById(id: string): Observable<Template | undefined> {
    return this.ensureInitialized().pipe(
      map(() => this.#templates$.value.find(template => template.id === id))
    );
  }

  previewTemplate(id: string): Observable<void> {
    return this.templatesApiService.previewTemplate(id);
  }

  getDataCollectionTemplateName(templateId: string): Observable<string> {
    return this.ensureInitialized().pipe(
      map(() => {
        const template = this.#templates$.value.find(template => template.id === templateId);
        return template?.name || '';
      })
    );
  }

  getUIWarehouseTemplates(templateIdList: string[]): Observable<SelectOption<TemplateId>[]> {
    return this.ensureInitialized().pipe(
      map(() =>
        templateIdList
          .map(templateId => {
            const template = this.#templates$.value.find(t => t.id === templateId);
            return template ? {
              key: template.id,
              value: template.name,
              desc: template.shortDescription,
              selected: false,
              category: template.category,
            } : null;
          })
          .filter(Boolean)
          .sort((a, b) => a!.value.localeCompare(b!.value)) as SelectOption<TemplateId>[]
      )
    );
  }

  private getTemplateList(
    templatesList: Template[],
    templateCategory: TemplateCategory,
  ): SelectOption<TemplateId>[] {
    return templatesList.map(({ category, id, shortDescription, name }) =>
      category === templateCategory
        ? { key: id as unknown as TemplateId, value: name, desc: shortDescription }
        : null,
    )
      .filter((item): item is { key: TemplateId; value: string; desc: string } => item !== null)
      .sort((a, b) => a.value.localeCompare(b.value));
  }
}
