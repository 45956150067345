import { Pipe, PipeTransform } from '@angular/core';

/**
 * Angular Pipe for capitalizing the first letter of a string.
 *
 * This pipe transforms the input string by capitalizing the first letter
 * and returning the modified string. If the input string is falsy (null or undefined),
 * it returns an empty string.
 *
 * @usageNotes
 * ```
 * // In your Angular template:
 * {{ someString | capitalizeFirstLetter }}
 * ```
 *
 * @example
 * // In an Angular component:
 * const capitalizedString = this.capitalizePipe.transform(someString);
 */
@Pipe({ name: 'capitalizeFirstLetter' })
export class CapitalizeFirstLetterPipe implements PipeTransform {
  /**
   * Transform the input string by capitalizing its first letter.
   *
   * @function
   * @param {string | null | undefined} value - The input string to be transformed.
   * @returns {string} - String with the first letter capitalized. If `value` is falsy, it returns an empty string.
   */
  transform = (value?: string | null): string => (value ? `${value.charAt(0).toUpperCase()}${value.slice(1)}` : '');
}
