import { Injectable } from '@angular/core';

import { first, Observable, tap } from 'rxjs';

import { GroupLoaderKey } from '../../enums';
import { Group, ServiceCreate, ServiceDelete, ServiceGet, ServiceList, ServiceUpdate } from '../../models';
import { ApiService, LoadingService, SnackbarService } from '../../services';

const ENDPOINT = 'v1/groups';

@Injectable({ providedIn: 'root' })
export class GroupsApiService
implements
  ServiceGet<Group>,
  ServiceList<Group[]>,
  ServiceCreate<Group, Partial<Group>>,
  ServiceUpdate<Group, Partial<Group>>,
  ServiceDelete<{ id: string }>
{
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  get(id: string): Observable<Group> {
    return this.api
      .get<Group>(`${ENDPOINT}/${id}`)
      .pipe(first(), this.loader.set(GroupLoaderKey.GET_USER_GROUP), this.snack.operator());
  }

  list(): Observable<Group[]> {
    return this.api.get<Group[]>(ENDPOINT).pipe(first(), this.loader.set(GroupLoaderKey.LIST_USER_GROUPS));
  }

  create(payload: Partial<Group>): Observable<Group> {
    return this.api.post<Group, Partial<Group>>(ENDPOINT, payload).pipe(
      first(),
      this.loader.set(GroupLoaderKey.CREATE_USER_GROUP),
      tap({
        complete: () => this.snack.success('Group created'),
        error: () => this.snack.error('Please choose a unique name'),
      }),
    );
  }

  update(id: string, payload: Partial<Group>): Observable<Group> {
    return this.api.put<Group, Partial<Group>>(ENDPOINT, id, payload).pipe(
      first(),
      this.loader.set(GroupLoaderKey.UPDATE_USER_GROUP),
      tap({
        complete: () => this.snack.success('Group updated'),
        error: () => this.snack.error('Please choose a unique name'),
      }),
    );
  }

  delete(id: string): Observable<{ id: string }> {
    return this.api
      .delete<{ id: string }>(ENDPOINT, id)
      .pipe(first(), this.loader.set(GroupLoaderKey.DELETE_USER_GROUP), this.snack.operator('Group deleted'));
  }

  detachRole(groupId: string, roleId: string): Observable<Group> {
    return this.api
      .detach<Group>(`${ENDPOINT}/${groupId}/role/${roleId}`)
      .pipe(first(), this.loader.set(GroupLoaderKey.DETACH_GROUP_ROLE), this.snack.operator('Role removed from group'));
  }

  detachUser(groupId: string, userId: string): Observable<Group> {
    return this.api
      .detach<Group>(`${ENDPOINT}/${groupId}/user/${userId}`)
      .pipe(first(), this.loader.set(GroupLoaderKey.DETACH_GROUP_USER), this.snack.operator('User removed from group'));
  }

  addRoles(groupId: string, payload: string[]): Observable<Group> {
    return this.api
      .post<Group, string[]>(`${ENDPOINT}/${groupId}/roles`, payload)
      .pipe(first(), this.loader.set(GroupLoaderKey.ADD_GROUP_ROLES), this.snack.operator('Roles added to group'));
  }

  addUsers(groupId: string, users: string[]): Observable<Group> {
    return this.api
      .post<Group, string[]>(`${ENDPOINT}/${groupId}/users`, users)
      .pipe(first(), this.loader.set(GroupLoaderKey.ADD_GROUP_USERS), this.snack.operator('Users added to group'));
  }
}
