import { Template } from '@core/models/template';
import { getDateMonthsAgo, getFirstDayOfMonth } from '../utils';

/**
 * Get Data Collection Template name by template ID.
 *
 * @function
 * @param {Template[]} templatesList - templates list.
 * @param {string} templateId - template ID.
 * @returns {string} - emission template name.
 */
export const getDataCollectionTemplateName = (
  templatesList: Template[],
  templateId: string
): string => {
  const template = templatesList.find((template) => template.id === templateId);

  return template?.name ? template.name : '';
};

/**
 * Get the first day of the reporting month for emissions based on a specified number of months ago.
 *
 * @function
 * @param {number} monthAgo - The number of months ago to retrieve the reporting month.
 * @returns {string} - An ISO string representing the first day of the reporting month.
 * @example "2023-01-01T00:00:00.000Z"
 */
export const getEmissionsFirstDayOfReportingMonth = (monthAgo: number): string => {
  const dateMonthAgo = getDateMonthsAgo(monthAgo);
  const year = dateMonthAgo.getFullYear();
  const month = dateMonthAgo.getMonth();

  return getFirstDayOfMonth(year, month);
};

/**
 * Get the last day of the reporting month for emissions based on a specified number of months ago.
 *
 * @function
 * @param {number} monthAgo - The number of months ago to retrieve the reporting month.
 * @returns {string} - An ISO string representing the last day of the reporting month.
 * @example "2023-11-30T23:59:59.999Z"
 */
export const getEmissionsLastDayOfReportingMonth = (monthAgo: number): string => {
  const date = new Date(getEmissionsFirstDayOfReportingMonth(monthAgo));
  date.setMilliseconds(date.getMilliseconds() - 1);

  return date.toISOString();
};
