import { Exclude, Expose } from 'class-transformer';

import { DEFAULT_ALERT_CONDITION } from '@core/constants';
import { AlertKeyCondition } from '@core/enums';
import { IAlert, SelectOption, SimpleRecipient } from '@core/models';

import { AlertEventPipe } from '../pipes';

@Exclude()
export class Alert implements IAlert {
  @Expose()
    id!: string;

  @Expose()
    createdAt!: string;

  @Expose()
    updatedAt!: string | null;

  @Expose()
    name!: string;

  @Expose()
    event!: string;

  @Expose()
    conditions!: SelectOption<AlertKeyCondition>[];

  @Expose()
    recipients!: SimpleRecipient[];

  get messageType(): string {
    const messageType = this.conditions?.find((condition) => condition.key === AlertKeyCondition.MESSAGE_TYPE);

    return messageType?.value ?? DEFAULT_ALERT_CONDITION;
  }

  get receiverId(): string {
    const senderName = this.conditions?.find((condition) => condition.key === AlertKeyCondition.RECEIVER);

    return senderName?.value ?? DEFAULT_ALERT_CONDITION;
  }

  get eventName(): string {
    return new AlertEventPipe().transform(this.event);
  }

  get recipientsNameList(): string[] {
    return this.recipients.map((recipient) => recipient.name ?? '');
  }
}
