import { BadgeType } from '@shared/badge';

export interface Badge<T> {
  key: T;
  value: string;
  background?: string;
  color?: string;
  type?: BadgeType;
  badgeStyle?: string;
}
