import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';

import { Group } from '../../models';
import { GroupsApiService } from '../../services';

@Injectable({ providedIn: 'root' })
export class GroupsService {
  get groupList$(): Observable<Group[]> {
    return this.#groupList$.asObservable();
  }

  get group$(): Observable<Group> {
    return this.#group$.asObservable();
  }

  readonly #groupList$ = new BehaviorSubject<Group[]>([]);
  readonly #group$ = new BehaviorSubject<Group>({} as Group);

  constructor(private groupsApiService: GroupsApiService) {}

  getAll(): Observable<Group[]> {
    return this.groupsApiService.list().pipe(tap((groups) => this.#groupList$.next(groups)));
  }

  getById(groupId: string): Observable<Group> {
    return this.groupsApiService.get(groupId).pipe(tap((group) => this.#group$.next(group)));
  }

  create(payload: Partial<Group>): Observable<Group[]> {
    return this.groupsApiService.create(payload).pipe(switchMap(() => this.getAll()));
  }

  update(groupId: string, payload: Partial<Group>): Observable<Group[]> {
    return this.groupsApiService.update(groupId, payload).pipe(switchMap(() => this.#updateGroupAndGroupList(groupId)));
  }

  delete(groupId: string): Observable<Group[]> {
    return this.groupsApiService.delete(groupId).pipe(switchMap(() => this.getAll()));
  }

  attachRole(groupId: string, payload: string[]): Observable<Group[]> {
    return this.groupsApiService
      .addRoles(groupId, payload)
      .pipe(switchMap(() => this.#updateGroupAndGroupList(groupId)));
  }

  detachRole(groupId: string, roleId: string): Observable<Group[]> {
    return this.groupsApiService
      .detachRole(groupId, roleId)
      .pipe(switchMap(() => this.#updateGroupAndGroupList(groupId)));
  }

  attachUsers(groupId: string, payload: string[]): Observable<Group> {
    return this.groupsApiService.addUsers(groupId, payload).pipe(switchMap(() => this.getById(groupId)));
  }

  detachUser(groupId: string, userId: string): Observable<Group> {
    return this.groupsApiService.detachUser(groupId, userId).pipe(switchMap(() => this.getById(groupId)));
  }

  #updateGroupAndGroupList(groupId: string): Observable<Group[]> {
    return this.getById(groupId).pipe(switchMap(() => this.getAll()));
  }
}
