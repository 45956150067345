import { Injectable } from '@angular/core';

import { BehaviorSubject, first, map, Observable, switchMap, tap } from 'rxjs';

import { plainToInstance } from 'class-transformer';

import { Alert } from '@shared/adapters';

import { AlertsApiService } from '../../services';

@Injectable({ providedIn: 'root' })
export class AlertsService {
  get alertList$(): Observable<Alert[]> {
    return this.#alertList$.asObservable();
  }

  readonly #alertList$ = new BehaviorSubject<Alert[]>([]);

  constructor(private alertApiService: AlertsApiService) {}

  getAll(): Observable<Alert[]> {
    return this.alertApiService.list().pipe(
      first(),
      map((alerts) => plainToInstance(Alert, alerts)),
      tap((alerts) => this.#alertList$.next(alerts)),
    );
  }

  create(payload: Partial<Alert>): Observable<Alert[]> {
    return this.alertApiService.create(payload).pipe(
      first(),
      switchMap(() => this.getAll()),
    );
  }

  update(alertId: string, payload: Partial<Alert>): Observable<Alert[]> {
    return this.alertApiService.update(alertId, payload).pipe(
      first(),
      switchMap(() => this.getAll()),
    );
  }

  delete(alertId: string): Observable<Alert[]> {
    return this.alertApiService.delete(alertId).pipe(
      first(),
      switchMap(() => this.getAll()),
    );
  }
}
