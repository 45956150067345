import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, EMPTY, first, Observable, tap } from 'rxjs';

import { Errors } from '../../constants';
import { UsersLoaderKey } from '../../enums';
import {
  Group,
  IUser,
  IUserWithPartner,
  IUserWithPermissions,
  ListUsersPayload,
  ServiceCreate,
  ServiceDelete,
  ServiceGet,
  ServiceList,
} from '../../models';
import { ApiService, LoadingService, SnackbarService } from '../../services';

const ENDPOINT = 'v1/users';

@Injectable({ providedIn: 'root' })
export class UsersApiService
implements
  ServiceGet<IUserWithPermissions>,
  ServiceList<IUserWithPartner[], ListUsersPayload>,
  ServiceCreate<IUser, Partial<IUser>>,
  ServiceDelete<{ id: string }>
{
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  get(id: string): Observable<IUserWithPermissions> {
    return this.api
      .get<IUserWithPermissions>(`${ENDPOINT}/${id}`)
      .pipe(first(), this.loader.set(UsersLoaderKey.GET_USER), this.snack.operator());
  }

  list(payload?: ListUsersPayload): Observable<IUserWithPartner[]> {
    let api: Observable<IUserWithPartner[]> = this.api.get<IUserWithPartner[]>(ENDPOINT);

    if (payload?.includeOnlyInternalUsers) {
      const params = new HttpParams().set('internal', 'true');

      api = this.api.get<IUserWithPartner[]>(ENDPOINT, { params });
    }

    return api.pipe(first(), this.loader.set(UsersLoaderKey.LIST_USERS), this.snack.operator());
  }

  create(payload: Partial<IUser>): Observable<IUser> {
    return this.api.post<IUser, Partial<IUser>>(ENDPOINT, payload).pipe(
      first(),
      this.loader.set(UsersLoaderKey.SAVE_USER),
      tap({
        complete: () => this.snack.success('Users invited'),
        error: (err) => {
          const errors = err.error.message.map((message: string) => Errors[message]);

          this.snack.error(errors.join('\n'));
        },
      }),
    );
  }

  reSendInvite(id: string): Observable<IUser[]> {
    return this.api.put<IUser[], object>(`${ENDPOINT}/invite`, id, {}).pipe(
      first(),
      this.snack.operator('Invite Sent'),
      catchError(() => {
        this.snack.error('Invite failed to send');

        return EMPTY;
      }),
    );
  }

  delete(id: string): Observable<{ id: string }> {
    return this.api
      .delete<{ id: string }>(ENDPOINT, id)
      .pipe(first(), this.loader.set(UsersLoaderKey.DELETE_USER), this.snack.operator('User deleted'));
  }

  detachGroup(userId: string, groupId: string): Observable<IUser> {
    return this.api
      .detach<IUser>(`${ENDPOINT}/${userId}/group/${groupId}`)
      .pipe(first(), this.loader.set(UsersLoaderKey.DETACH_USER_GROUP), this.snack.operator('Group removed from user'));
  }

  addGroups(userId: string, payload: string[]): Observable<Group[]> {
    return this.api
      .post<Group[], string[]>(`${ENDPOINT}/${userId}/groups`, payload)
      .pipe(first(), this.loader.set(UsersLoaderKey.ATTACH_USER_GROUP), this.snack.operator('Groups added to user'));
  }
}
