import { Injectable } from '@angular/core';

import { first, Observable } from 'rxjs';

import { ApiService, LoadingService, SnackbarService } from '..';
import { Template } from '@core/models/template';
import { TemplateLoaderKey } from '@core/enums/template.enum';

const ENDPOINT = 'v1/templates';

@Injectable({ providedIn: 'root' })
export class TemplatesApiService {
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  getTemplates(): Observable<Template[]> {
    return this.api.get<Template[]>(`${ENDPOINT}`)
      .pipe(first(), this.loader.set(TemplateLoaderKey.LIST_TEMPLATES), this.snack.operator());
  }

  previewTemplate(id: string): Observable<void> {
    return this.api
      .get<void>(`${ENDPOINT}/${id}/preview`)
      .pipe(first(), this.loader.set(TemplateLoaderKey.PREVIEW_TEMPLATE), this.snack.operator());
  }
}
