export enum TemplateId {
  TRANSPORT_1 = 'Transport_Self_Reporting_1',
  TRANSPORT_2 = 'Transport_Self_Reporting_2',
  TRANSPORT_3 = 'Transport_Self_Reporting_3',
  TRANSPORT_4 = 'Transport_Calculated_4',
  TRANSPORT_5 = 'Transport_Calculated_5',
  TRANSPORT_6 = 'Transport_Calculated_6',
  WAREHOUSE_1 = 'Warehouse_Self_Reported_1',
  WAREHOUSE_2 = 'Warehouse_Calculated_2',
  INVOICE_1 = 'Invoice_1'
}

export enum TemplateType {
  EMISSIONS = 'emissions',
  FINANCE = 'finance'
}

export enum TemplateCategory {
  TRANSPORT = 'transport',
  WAREHOUSE = 'warehouse',
  FINANCE = 'finance'
}

export enum TemplateFormat {
  FILE_UPLOAD = 'file_upload',
  MANUAL_ENTRY = 'manual_entry',
  INTEGRATED = 'integrated'
}

export interface Template {
  id: TemplateId;
  name: string;
  type: TemplateType;
  category: TemplateCategory | null;
  format: TemplateFormat;
  downloadPreviewName: string | null;
  shortDescription: string | null;
  description: string | null;
  enabled: number;
  createdAt: Date;
}
