<form [formGroup]="form">
  <div class="grid grid-cols-3 gap-4">
    <mat-form-field class="col-span-2">
      <mat-label>Cargo Weight ambient</mat-label>
      <input
        matInput
        formControlName="Cargo Weight ambient"
        type="number"
        step="1"
        min="0" />
    </mat-form-field>
    <app-uom
      formControlName="UOM Cargo Weight ambient"
      [category]="MeasurementSystemCategory.WEIGHT"
      [system]="system"></app-uom>
  </div>

  <div class="grid grid-cols-3 gap-4">
    <mat-form-field class="col-span-2">
      <mat-label>Cargo Weight cold chain</mat-label>
      <input
        matInput
        formControlName="Cargo Weight cold chain"
        type="number"
        step="1"
        min="0" />
    </mat-form-field>
    <app-uom
      formControlName="UOM Cargo Weight cold chain"
      [category]="MeasurementSystemCategory.WEIGHT"
      [system]="system"></app-uom>
  </div>
</form>
