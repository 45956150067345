import { ComponentType } from '@angular/cdk/overlay';
import { Injectable, InjectionToken } from '@angular/core';

import { Subject } from 'rxjs';

export const DRAWER_DATA = new InjectionToken('VeratrakDrawerData');

@Injectable({ providedIn: 'root' })
export class DrawerService<Template, CloseReason = { id: string; reason: string }> {
  private openedSource = new Subject<{ template: ComponentType<Template>; data: unknown }>();
  private closedSource = new Subject<CloseReason | undefined>();

  opened$ = this.openedSource.asObservable();
  closed$ = this.closedSource.asObservable();

  open(template: ComponentType<Template>, data?: unknown): void {
    this.openedSource.next({ template, data });
  }

  close(reason?: CloseReason): void {
    this.closedSource.next(reason);
  }
}
