import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { ActorService } from '../services';

@Injectable()
export class PermissionGuard {
  constructor(private actor: ActorService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const permissions = this.actor.get().permissions;

    return permissions.includes(route.data['permission']);
  }
}
