import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Distance, Weight } from '@core/constants/uom.constant';
import { MeasurementSystem, MeasurementSystemCategory } from '@core/enums/uom.enum';
import { atLeastOneFieldValidator } from '@src/app/shared/validators/at-least-one.validator';

@Component({
  selector: 'app-transport4',
  templateUrl: './transport4.component.html',
})
export class Transport4Component implements OnInit {
  @Input() system!: MeasurementSystem;
  @Input() disabled?: boolean;
  @Output() onChanges = new EventEmitter<FormGroup>();

  form = this.fb.group({
    ['Total distance road ambient']: [null, [Validators.pattern(/[1-9]\d*/)]],
    ['UOM Total distance road ambient']: null,
    ['Total weight road ambient']: [null, [Validators.pattern(/[1-9]\d*/)]],
    ['UOM total weight road ambient']: null,
    ['Total distance road cold chain']: [null, [Validators.pattern(/[1-9]\d*/)]],
    ['UOM Total distance road cold chain']: null,
    ['Total weight road cold chain']: [null, [Validators.pattern(/[1-9]\d*/)]],
    ['UOM total weight road cold chain']: null,
    ['Total distance air']: [null, [Validators.pattern(/[1-9]\d*/)]],
    ['UOM Total distance air']: null,
    ['Total weight air']: [null, [Validators.pattern(/[1-9]\d*/)]],
    ['UOM total weight air']: null,
    ['Total distance sea ambient']: [null, [Validators.pattern(/[1-9]\d*/)]],
    ['UOM distance sea ambient']: null,
    ['Total weight sea ambient']:[null, [Validators.pattern(/[1-9]\d*/)]],
    ['UOM total weight sea ambient']: null,
    ['Total distance sea cold chain']: [null, [Validators.pattern(/[1-9]\d*/)]],
    ['UOM Total distance sea cold chain']: null,
    ['Total weight sea cold chain']: [null, [Validators.pattern(/[1-9]\d*/)]],
    ['UOM total weight sea cold chain']: null,
  }, { validators: atLeastOneFieldValidator([
    'Total distance road ambient',
    'Total weight road ambient',
    'Total distance road cold chain',
    'Total weight road cold chain',
    'Total distance air',
    'Total weight air',
    'Total distance road sea ambient',
    'Total weight road sea ambient',
    'Total distance sea cold chain',
    'Total weight sea cold chain',
  ]) });

  protected readonly MeasurementSystemCategory: typeof MeasurementSystemCategory = MeasurementSystemCategory;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form.patchValue({
      ['UOM Total distance road ambient']: Distance[this.system].default,
      ['UOM total weight road ambient']: Weight[this.system].default,
      ['UOM Total distance road cold chain']: Distance[this.system].default,
      ['UOM total weight road cold chain']: Weight[this.system].default,
      ['UOM Total distance air']: Distance[this.system].default,
      ['UOM total weight air']: Weight[this.system].default,
      ['UOM distance sea ambient']: Distance[this.system].default,
      ['UOM total weight sea ambient']: Weight[this.system].default,
      ['UOM Total distance sea cold chain']: Distance[this.system].default,
      ['UOM total weight sea cold chain']: Weight[this.system].default,
    });

    if (this.disabled) {
      this.form.get('Total distance road ambient')?.disable();
      this.form.get('Total weight road ambient')?.disable();
      this.form.get('Total distance road cold chain')?.disable();
      this.form.get('Total weight road cold chain')?.disable();
      this.form.get('Total distance air')?.disable();
      this.form.get('Total weight air')?.disable();
      this.form.get('Total distance sea ambient')?.disable();
      this.form.get('Total weight sea ambient')?.disable();
      this.form.get('Total distance sea cold chain')?.disable();
      this.form.get('Total weight sea cold chain')?.disable();
    }

    this.form.valueChanges.subscribe(() => this.onChanges.emit(this.form));
  }
}
