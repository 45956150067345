import { Injectable } from '@angular/core';

import { first, Observable } from 'rxjs';

import { RoleLoaderKey } from '../../enums';
import { Role, ServiceCreate, ServiceDelete, ServiceGet, ServiceList, ServiceUpdate } from '../../models';
import { ApiService, LoadingService, SnackbarService } from '../../services';

const ENDPOINT = 'v1/roles';

@Injectable({ providedIn: 'root' })
export class RolesApiService
implements
  ServiceGet<Role>,
  ServiceList<Role[]>,
  ServiceCreate<Role, Partial<Role>>,
  ServiceUpdate<Role, Partial<Role>>,
  ServiceDelete<{ id: string }>
{
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  get(id: string): Observable<Role> {
    return this.api
      .get<Role>(`${ENDPOINT}/${id}`)
      .pipe(first(), this.loader.set(RoleLoaderKey.GET_ROLE), this.snack.operator());
  }

  list(): Observable<Role[]> {
    return this.api
      .get<Role[]>(ENDPOINT)
      .pipe(first(), this.loader.set(RoleLoaderKey.LIST_ROLES), this.snack.operator());
  }

  create(payload: Partial<Role>): Observable<Role> {
    return this.api.post<Role, Partial<Role>>(ENDPOINT, payload).pipe(
      first(),
      this.loader.set(RoleLoaderKey.CREATE_ROLE),
      this.snack.operator('Role created'),
    );
  }

  update(id: string, payload: Partial<Role>): Observable<Role> {
    return this.api.put<Role, Partial<Role>>(ENDPOINT, id, payload).pipe(
      first(),
      this.loader.set(RoleLoaderKey.UPDATE_ROLE),
      this.snack.operator('Role updated'),
    );
  }

  delete(id: string): Observable<{ id: string }> {
    return this.api.delete<{ id: string }>(ENDPOINT, id).pipe(
      first(),
      this.loader.set(RoleLoaderKey.DELETE_ROLE),
      this.snack.operator('Role deleted')
    );
  }
}
