import { UOMSymbol } from '../enums';

export interface UOMSelect {
  value: UOMSymbol;
  title: string;
}

export interface UOM {
  default: UOMSymbol;
  list: UOMSelect[];
}
