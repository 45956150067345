import { Injectable } from '@angular/core';

import { first, Observable, tap } from 'rxjs';

import { AlertLoaderKey } from '../../enums';
import { IAlert, ServiceCreate, ServiceDelete, ServiceGet, ServiceList, ServiceUpdate } from '../../models';
import { ApiService, LoadingService, SnackbarService } from '../../services';

export const ENDPOINT = 'v1/alerts';

@Injectable({ providedIn: 'root' })
export class AlertsApiService
implements
  ServiceGet<IAlert>,
  ServiceList<IAlert[]>,
  ServiceCreate<IAlert, Partial<IAlert>>,
  ServiceUpdate<IAlert, Partial<IAlert>>,
  ServiceDelete<{ id: string }>
{
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  get(id: string): Observable<IAlert> {
    return this.api
      .get<IAlert>(`${ENDPOINT}/${id}`)
      .pipe(first(), this.loader.set(AlertLoaderKey.GET_ALERT), this.snack.operator());
  }

  list(): Observable<IAlert[]> {
    return this.api
      .get<IAlert[]>(ENDPOINT)
      .pipe(first(), this.loader.set(AlertLoaderKey.LIST_ALERTS), this.snack.operator());
  }

  create(payload: Partial<IAlert>): Observable<IAlert> {
    return this.api
      .post<IAlert, Partial<IAlert>>(ENDPOINT, payload)
      .pipe(first(), this.loader.set(AlertLoaderKey.CREATE_ALERT), this.snack.operator('Alert rule created'));
  }

  update(id: string, payload: Partial<IAlert>): Observable<IAlert> {
    return this.api.put<IAlert, Partial<IAlert>>(ENDPOINT, id, payload).pipe(
      first(),
      this.loader.set(AlertLoaderKey.UPDATE_ALERT),
      this.snack.operator('Alert rule updated'),
    );
  }

  delete(id: string): Observable<{ id: string }> {
    return this.api
      .delete<{ id: string }>(ENDPOINT, id)
      .pipe(first(), this.loader.set(AlertLoaderKey.DELETE_ALERT), this.snack.operator('Alert rule deleted'));
  }
}
