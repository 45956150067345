import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesizeKb',
})
export class FilesizeKbPipe implements PipeTransform {

  transform(filesize: number): string {
    if (!filesize) {
      return '0 KB';
    }

    const kilobytes: number = filesize / 1024;
    return kilobytes.toFixed(2) + ' KB';
  }
}
