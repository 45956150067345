import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

type Format = 'd MMM y HH:mm' | 'd MMM y' | 'MMM y' | 'MMMM y' | 'MMMM d, y' | 'MMM d, y';

/**
 * Angular Pipe for converting and formatting dates in different timezones.
 *
 * This pipe uses the Angular `DatePipe` to transform and format dates based on the provided
 * format and timezone. If the input date is falsy (null or undefined), an empty string is returned.
 *
 * @usageNotes
 * ```
 * // In your Angular template:
 * {{ someDate | timezone:'d MMM y HH:mm':'UTC' }}
 * ```
 *
 * @example
 * // In an Angular component:
 * const formattedDate = this.timezonePipe.transform(someDate, 'd MMM y HH:mm', 'UTC');
 */
@Pipe({ name: 'timezone' })
export class TimezonePipe implements PipeTransform {
  /**
   * Transform and format the input date based on the provided format and timezone.
   *
   * @function
   * @param {string | null | undefined} value - The input date to be transformed and formatted.
   * @param {Format} format - The format to be applied to the date (default: 'd MMM y').
   * @returns {string | null} The transformed and formatted date. If `value` is falsy, it returns an empty string.
   */
  transform(value?: string | null, format: Format = 'd MMM y'): string | null {
    if (!value) {
      return '';
    }

    const datePipe = new DatePipe('en-US');

    return datePipe.transform(value, format);
  }
}
