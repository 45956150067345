import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatStringsArray'
})
export class FormatStringsArrayPipe implements PipeTransform {

  transform(value: string[] | null): string {
    if (!value || value.length === 0) {
      return '-';
    }

    return value.join(', ');
  }
}
