import { Badge, BadgeType } from '@shared/badge';


import { MeasurementSystem, WarehouseReportingStatus, WarehouseStatus } from '../enums';
import { SelectOption } from '../models';

/**
 * Warehouse status map with status badges.
 *
 * @constant
 * @type {Record<WarehouseStatus, Badge<WarehouseStatus>>}
 */
export const warehouseStatusMap: Record<WarehouseStatus, Badge<WarehouseStatus>> = {
  [WarehouseStatus.NEW]: {
    key: WarehouseStatus.NEW,
    value: 'New',
    type: BadgeType.NEW,
  },
  [WarehouseStatus.PRE_ONBOARDED]: {
    key: WarehouseStatus.PRE_ONBOARDED,
    value: 'Pre-onboarded',
    type: BadgeType.PROGRESS,
  },
  [WarehouseStatus.ONBOARDED]: {
    key: WarehouseStatus.ONBOARDED,
    value: 'Onboarded',
    type: BadgeType.SUCCESS,
  },
  [WarehouseStatus.INACTIVE]: {
    key: WarehouseStatus.INACTIVE,
    value: 'Inactive',
    type: BadgeType.ERROR,
  },
};

/**
 * Warehouse reporting status map with status badges.
 *
 * @constant
 * @type {Record<WarehouseReportingStatus, Badge<WarehouseReportingStatus>>}
 */
export const warehouseReportingStatusMap: Record<WarehouseReportingStatus, Badge<WarehouseReportingStatus>> = {
  [WarehouseReportingStatus.PARTIALLY_REPORTED]: {
    key: WarehouseReportingStatus.PARTIALLY_REPORTED,
    value: 'Partially Reported',
    type: BadgeType.PROGRESS,
  },
  [WarehouseReportingStatus.REPORTED]: {
    key: WarehouseReportingStatus.REPORTED,
    value: 'Reported',
    type: BadgeType.SUCCESS,
  },
  [WarehouseReportingStatus.NOT_REPORTED]: {
    key: WarehouseReportingStatus.NOT_REPORTED,
    value: 'Not Reported',
    type: BadgeType.DISABLED,
  },
};

/**
 * List of Warehouse Statuses.
 *
 * @constant
 * @type {SelectOption<WarehouseStatus>[]}
 */
export const warehouseStatusList: SelectOption<WarehouseStatus>[] = Object.values(warehouseStatusMap).map(
  ({ key, value }) => ({ key, value }),
);

/**
 * List of Warehouse Reporting Statuses.
 *
 * @constant
 * @type {SelectOption<WarehouseReportingStatus>[]}
 */
export const warehouseReportingStatusList: SelectOption<WarehouseReportingStatus>[] = Object.values(
  warehouseReportingStatusMap,
).map(({ key, value }) => ({ key, value }));

/**
 * List of Warehouse Measurement System.
 *
 * @constant
 * @type {SelectOption<MeasurementSystem>[]}
 */
export const measurementSystemList: SelectOption<MeasurementSystem>[] = [
  { key: MeasurementSystem.METRIC, value: 'Metric' },
  { key: MeasurementSystem.IMPERIAL, value: 'Imperial' },
];

/**
 * Map of Warehouse Measurement System.
 *
 * @constant
 * @type {Record<MeasurementSystem, string>}
 */
export const measurementSystemMap: Record<MeasurementSystem, string> = measurementSystemList.reduce(
  (acc, { key, value }) => ({ ...acc, [key]: value }),
  {} as Record<MeasurementSystem, string>,
);
