import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { MeasurementSystem } from '@core/enums/uom.enum';

import { TemplateDialogData } from '../models/template-dialog-data.model';

@Component({
  selector: 'app-transport4dialog',
  template: ` <mat-dialog-content>
    <h2>Transport 4 – Monthly Total Volumes per Transport Mode (Entry)</h2>
    <app-uom-switcher
      *ngIf="isUomSwitcherEnabled"
      [system]="system"
      (systemChange)="onSystemChange($event)">
    </app-uom-switcher>
    <app-transport4
      [system]="system"
      [disabled]="disabled">
    </app-transport4>
  </mat-dialog-content>`,
})
export class Transport4DialogComponent {
  system: MeasurementSystem;
  isUomSwitcherEnabled: boolean;
  disabled: boolean;

  protected readonly MeasurementSystem: typeof MeasurementSystem = MeasurementSystem;

  constructor(@Inject(MAT_DIALOG_DATA) private data: TemplateDialogData) {
    this.system = data.system || MeasurementSystem.METRIC;
    this.isUomSwitcherEnabled = data.isUomSwitcherEnabled ?? false;
    this.disabled = data.disabled ?? true;
  }

  onSystemChange(system: MeasurementSystem): void {
    this.system = system;
  }
}
