import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { first, iif, Observable, switchMap } from 'rxjs';

import { AuthService, User } from '@auth0/auth0-angular';

import { environment } from '@src/environments/environment';

@Injectable()
export class CidInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return iif(
      () => req.url.includes(`${environment.apiHost}/v1`) || req.url.includes(`${environment.apiHost}/auth/userinfo`),
      this.auth.user$.pipe(
        first(),
        switchMap((user: User | null | undefined) => {
          let cid = sessionStorage.getItem('cid') as string;

          if (!cid) {
            const meta = user ? user['https://app.veratrak.com/metadata'] : null;
            cid = meta?.iwpCompanies[0];
            sessionStorage.setItem('cid', cid);
          }

          const clone = req.clone({
            headers: req.headers.set('cid', cid),
          });

          return next.handle(clone);
        }),
      ),
      next.handle(req),
    );
  }
}
