/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { finalize, Observable } from 'rxjs';

import { LoadingQuery } from './loading.query';
import { LoadingStore } from './loading.store';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  constructor(
    private store: LoadingStore,
    private query: LoadingQuery,
  ) {}

  set(key: string) {
    return (source: any): Observable<any> => {
      this.store.update({ [key]: true });

      return source.pipe(finalize(() => this.store.update({ [key]: false })));
    };
  }

  get(key: string): Observable<boolean> {
    return this.query.select((state: any) => state[key]);
  }
}
