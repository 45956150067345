import { AlertEvent } from '../models';

export const DEFAULT_ALERT_EVENT = 'message.error';

export const DEFAULT_ALERT_CONDITION = 'Any';

export const AlertEvents: AlertEvent[] = [
  { key: 'message.error', name: 'Message error', desc: '', keys: [{ key: 'type', name: 'message type' }] },
  // { key: 'user.invited', name: 'User invited', desc: '', keys: [] },
  // { key: 'user.updated', name: 'User updated', desc: '', keys: [] },
  // { key: 'user.deleted', name: 'User deleted', desc: '', keys: [] },
  // { key: 'role.created', name: 'Role created', desc: '', keys: [] },
  // { key: 'role.updated', name: 'Role updated', desc: '', keys: [] },
  // { key: 'role.deleted', name: 'Role deleted', desc: '', keys: [] },
  // { key: 'group.created', name: 'Group created', desc: '', keys: [] },
  // { key: 'group.updated', name: 'Group updated', desc: '', keys: [] },
  // { key: 'group.deleted', name: 'Group deleted', desc: '', keys: [] },
];
