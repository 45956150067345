import { TaskStatus } from '@core/enums';
import { Badge, BadgeType } from '@shared/badge';

export const TaskStatusMap: Record<TaskStatus, Badge<TaskStatus>> = {
  [TaskStatus.FAILED]: {
    key: TaskStatus.FAILED,
    value: TaskStatus.FAILED,
    type: BadgeType.ERROR,
  },
  [TaskStatus.NOT_REPORTED]: {
    key: TaskStatus.NOT_REPORTED,
    value: TaskStatus.NOT_REPORTED,
    type: BadgeType.DISABLED,
  },
};
