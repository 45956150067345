<form [formGroup]="form">
  <div class="mb-4">
    <div class="grid grid-cols-3 gap-4">
      <mat-form-field class="col-span-2">
        <mat-label>Total distance road ambient</mat-label>
        <input
          matInput
          formControlName="Total distance road ambient"
          type="number"
          step="1"
          min="0" />
      </mat-form-field>
      <app-uom
        formControlName="UOM Total distance road ambient"
        [category]="MeasurementSystemCategory.DISTANCE"
        [system]="system"></app-uom>
    </div>

    <div class="grid grid-cols-3 gap-4">
      <mat-form-field class="col-span-2">
        <mat-label>Total weight road ambient</mat-label>
        <input
          matInput
          formControlName="Total weight road ambient"
          type="number"
          step="1"
          min="0" />
      </mat-form-field>
      <app-uom
        formControlName="UOM total weight road ambient"
        [category]="MeasurementSystemCategory.WEIGHT"
        [system]="system"></app-uom>
    </div>
  </div>

  <div class="mb-4">
    <div class="grid grid-cols-3 gap-4">
      <mat-form-field class="col-span-2">
        <mat-label>Total distance road cold chain</mat-label>
        <input
          matInput
          formControlName="Total distance road cold chain"
          type="number"
          step="1"
          min="0" />
      </mat-form-field>
      <app-uom
        formControlName="UOM Total distance road cold chain"
        [category]="MeasurementSystemCategory.DISTANCE"
        [system]="system"></app-uom>
    </div>

    <div class="grid grid-cols-3 gap-4">
      <mat-form-field class="col-span-2">
        <mat-label>Total weight road cold chain</mat-label>
        <input
          matInput
          formControlName="Total weight road cold chain"
          type="number"
          step="1"
          min="0" />
      </mat-form-field>
      <app-uom
        formControlName="UOM total weight road cold chain"
        [category]="MeasurementSystemCategory.WEIGHT"
        [system]="system"></app-uom>
    </div>
  </div>

  <div class="mb-4">
    <div class="grid grid-cols-3 gap-4">
      <mat-form-field class="col-span-2">
        <mat-label>Total distance air</mat-label>
        <input
          matInput
          formControlName="Total distance air"
          type="number"
          step="1"
          min="0" />
      </mat-form-field>
      <app-uom
        formControlName="UOM Total distance air"
        [category]="MeasurementSystemCategory.DISTANCE"
        [system]="system"></app-uom>
    </div>

    <div class="grid grid-cols-3 gap-4">
      <mat-form-field class="col-span-2">
        <mat-label>Total weight air</mat-label>
        <input
          formControlName="Total weight air"
          matInput
          type="number"
          step="1"
          min="0" />
      </mat-form-field>
      <app-uom
        formControlName="UOM total weight air"
        [category]="MeasurementSystemCategory.WEIGHT"
        [system]="system"></app-uom>
    </div>
  </div>

  <div class="mb-4">
    <div class="grid grid-cols-3 gap-4">
      <mat-form-field class="col-span-2">
        <mat-label>Total distance sea ambient</mat-label>
        <input
          matInput
          formControlName="Total distance sea ambient"
          type="number"
          step="1"
          min="0" />
      </mat-form-field>
      <app-uom
        formControlName="UOM distance sea ambient"
        [category]="MeasurementSystemCategory.DISTANCE"
        [system]="system"></app-uom>
    </div>

    <div class="grid grid-cols-3 gap-4">
      <mat-form-field class="col-span-2">
        <mat-label>Total weight sea ambient</mat-label>
        <input
          matInput
          formControlName="Total weight sea ambient"
          type="number"
          step="1"
          min="0" />
      </mat-form-field>
      <app-uom
        formControlName="UOM total weight sea ambient"
        [category]="MeasurementSystemCategory.WEIGHT"
        [system]="system"></app-uom>
    </div>
  </div>

  <div class="mb-4">
    <div class="grid grid-cols-3 gap-4">
      <mat-form-field class="col-span-2">
        <mat-label>Total distance sea cold chain</mat-label>
        <input
          matInput
          formControlName="Total distance sea cold chain"
          type="number"
          step="1"
          min="0" />
      </mat-form-field>
      <app-uom
        formControlName="UOM Total distance sea cold chain"
        [category]="MeasurementSystemCategory.DISTANCE"
        [system]="system"></app-uom>
    </div>

    <div class="grid grid-cols-3 gap-4">
      <mat-form-field class="col-span-2">
        <mat-label>Total weight sea cold chain</mat-label>
        <input
          matInput
          formControlName="Total weight sea cold chain"
          type="number"
          step="1"
          min="0" />
      </mat-form-field>
      <app-uom
        formControlName="UOM total weight sea cold chain"
        [category]="MeasurementSystemCategory.WEIGHT"
        [system]="system"></app-uom>
    </div>
  </div>
</form>
