import { Exclude, Expose } from 'class-transformer';

import { PERMISSIONS } from '@core/constants';
import { Permission, RoleType } from '@core/enums';
import { Role, SelectOption } from '@core/models';
import { getValidatedPermissionKeyList, permissionKeyToHumanValue, permissionMap } from '@core/utils';

@Exclude()
export class RoleDetails implements Partial<Role> {
  @Expose()
    id!: string;

  @Expose()
    createdAt!: string;

  @Expose()
    updatedAt!: string | null;

  @Expose()
    name!: string;

  @Expose()
    description!: string;

  @Expose()
    type!: RoleType;

  @Expose()
    permissions!: Permission[];

  get permissionList(): SelectOption<Permission>[] {
    return this.type === RoleType.ADMIN
      ? PERMISSIONS.map(({ key, desc }) => ({ key, value: permissionKeyToHumanValue(key), desc }))
      : getValidatedPermissionKeyList(this.permissions).map((permission) => permissionMap[permission]);
  }
}
