import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { first, Observable } from 'rxjs';

import { WarehouseLoaderKey } from '../enums';
import {
  AddWarehouseContactPayload,
  CreateWarehouseContactPayload,
  DashboardWarehousesListParams,
  EditWarehouseContactPayload,
  IWarehouseHistory,
  Response,
  ServiceCreate,
  ServiceDelete,
  ServiceGet,
  ServiceList,
  ServiceUpdate,
  TradingPartnerUsers,
  Warehouse,
  WarehouseContact,
  WarehouseContactDeleteParams,
  WarehouseContactsParams,
  WarehouseHistoryParams,
  WarehouseListParams,
} from '../models';
import { ApiService, LoadingService, SnackbarService } from '../services';

const ENDPOINT = 'v1/warehouses';

@Injectable({ providedIn: 'root' })
export class WarehousesService
implements
  ServiceGet<Warehouse>,
  ServiceList<Response<Warehouse>, WarehouseListParams>,
  ServiceCreate<Warehouse, Partial<Warehouse>>,
  ServiceUpdate<Warehouse, Partial<Warehouse>>,
  ServiceDelete<{ id: string }>
{
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  get(id: string): Observable<Warehouse> {
    return this.api
      .get<Warehouse>(`${ENDPOINT}/${id}`)
      .pipe(first(), this.loader.set('warehouse'), this.snack.operator());
  }

  list(options: WarehouseListParams): Observable<Response<Warehouse>> {
    const { page = 1, limit, sort, order, extraParams } = options;

    const data = {
      page,
      ...(limit && { limit }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(extraParams && { ...extraParams }),
    };

    const params = new HttpParams({ fromObject: data });

    return this.api
      .get<Response<Warehouse>>(`${ENDPOINT}`, { params })
      .pipe(first(), this.loader.set(WarehouseLoaderKey.LIST_WAREHOUSES), this.snack.operator());
  }

  create(payload: Partial<Warehouse>): Observable<Warehouse> {
    return this.api
      .post<Warehouse, Partial<Warehouse>>(ENDPOINT, payload)
      .pipe(first(), this.loader.set(WarehouseLoaderKey.CREATE_WAREHOUSE), this.snack.operator('Warehouse created'));
  }

  update(id: string, payload: Partial<Warehouse>): Observable<Warehouse> {
    return this.api
      .put<Warehouse, Partial<Warehouse>>(ENDPOINT, id, payload)
      .pipe(first(), this.loader.set(WarehouseLoaderKey.UPDATE_WAREHOUSE), this.snack.operator('Warehouse updated'));
  }

  delete(id: string): Observable<{ id: string }> {
    return this.api
      .delete<{ id: string }>(ENDPOINT, id)
      .pipe(first(), this.loader.set(WarehouseLoaderKey.DELETE_WAREHOUSE), this.snack.operator('Warehouse deleted'));
  }

  listWarehouseContactsUsers(warehouseId: string): Observable<TradingPartnerUsers[]> {
    return this.api.get<TradingPartnerUsers[]>(`${ENDPOINT}/${warehouseId}/users`).pipe(first());
  }

  listWarehouseContacts(options: WarehouseContactsParams): Observable<Response<WarehouseContact>> {
    const { page = 1, limit, sort, order, warehouseId } = options;

    const data = { page, ...(limit && { limit }), ...(sort && { sort }), ...(order && { order }) };

    const params = new HttpParams({ fromObject: data });

    return this.api
      .get<Response<WarehouseContact>>(`${ENDPOINT}/${warehouseId}/contacts`, { params })
      .pipe(first(), this.loader.set(WarehouseLoaderKey.LIST_WAREHOUSE_CONTACTS), this.snack.operator());
  }

  addWarehouseContact(payload: AddWarehouseContactPayload): Observable<WarehouseContact> {
    const { warehouseId } = payload;

    return this.api
      .post<WarehouseContact, AddWarehouseContactPayload>(`${ENDPOINT}/${warehouseId}/contacts/add-user`, payload)
      .pipe(
        first(),
        this.loader.set(WarehouseLoaderKey.ADD_WAREHOUSE_CONTACT),
        this.snack.operator('Warehouse Contact Added'),
      );
  }

  createWarehouseContact(payload: CreateWarehouseContactPayload): Observable<WarehouseContact> {
    const { warehouseId } = payload;

    return this.api
      .post<WarehouseContact, CreateWarehouseContactPayload>(`${ENDPOINT}/${warehouseId}/contacts/create-user`, payload)
      .pipe(
        first(),
        this.loader.set(WarehouseLoaderKey.CREATE_WAREHOUSE_CONTACT),
        this.snack.operator('Warehouse Contact Created'),
      );
  }

  editWarehouseContact(payload: EditWarehouseContactPayload): Observable<WarehouseContact> {
    const { warehouseId, contactId } = payload;

    return this.api
      .put<WarehouseContact, EditWarehouseContactPayload>(`${ENDPOINT}/${warehouseId}/contacts`, contactId, payload)
      .pipe(
        first(),
        this.loader.set(WarehouseLoaderKey.UPDATE_WAREHOUSE_CONTACT),
        this.snack.operator('Warehouse Contact Updated'),
      );
  }

  deleteWarehouseContact(options: WarehouseContactDeleteParams): Observable<{ id: string }> {
    const { id, warehouseId } = options;

    return this.api
      .delete<{ id: string }>(`${ENDPOINT}/${warehouseId}/contacts`, id)
      .pipe(
        first(),
        this.loader.set(WarehouseLoaderKey.DELETE_WAREHOUSE_CONTACT),
        this.snack.operator('Warehouse Contact removed'),
      );
  }

  listWarehouseHistory(options: WarehouseHistoryParams): Observable<Response<IWarehouseHistory>> {
    const { page = 1, limit, sort, order, warehouseId } = options;

    const data = { page, ...(limit && { limit }), ...(sort && { sort }), ...(order && { order }) };

    const params = new HttpParams({ fromObject: data });

    return this.api
      .get<Response<IWarehouseHistory>>(`${ENDPOINT}/${warehouseId}/history`, { params })
      .pipe(first(), this.loader.set(WarehouseLoaderKey.LIST_WAREHOUSE_HISTORY), this.snack.operator());
  }

  listDashboardWarehouses(options: DashboardWarehousesListParams): Observable<Response<Warehouse>> {
    const { page = 1, limit, sort, order, extraParams } = options;

    const data = {
      page,
      ...(limit && { limit }),
      ...(sort && { sort }),
      ...(order && { order }),
      ...(extraParams && extraParams),
    };

    const params = new HttpParams({ fromObject: data });

    return this.api
      .get<Response<Warehouse>>(`${ENDPOINT}/dashboard`, { params })
      .pipe(first(), this.loader.set(WarehouseLoaderKey.LIST_DASHBOARD_WAREHOUSES), this.snack.operator());
  }
}
