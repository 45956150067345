import { AuditLogCategory, AuditLogEvent } from '../enums';
import { SelectOption } from '../models';

export const auditLogEventList: SelectOption<AuditLogEvent>[] = [
  { key: AuditLogEvent.CREATED, value: 'Created' },
  { key: AuditLogEvent.UPDATED, value: 'Updated' },
  { key: AuditLogEvent.DELETED, value: 'Deleted' },
  { key: AuditLogEvent.DOWNLOADED, value: 'Downloaded' },
  { key: AuditLogEvent.PREVIEWED, value: 'Previewed' },
];

export const auditLogCategoryList: SelectOption<AuditLogCategory>[] = [
  { key: AuditLogCategory.USER, value: 'User' },
  { key: AuditLogCategory.GROUP, value: 'Group' },
  { key: AuditLogCategory.ROLE, value: 'Role' },
  { key: AuditLogCategory.ALERT, value: 'Alert' },
  { key: AuditLogCategory.WAREHOUSE, value: 'Warehouse' },
  { key: AuditLogCategory.WAREHOUSE_CONTACT, value: 'Warehouse Contact' },
  { key: AuditLogCategory.TEMPLATE, value: 'Template' },
];
