import { Pipe, PipeTransform } from '@angular/core';

/**
 * Angular Pipe for formatting values.
 *
 * The `FormatValuePipe` is a custom pipe that transforms and formats string or number values.
 * It provides a transformation logic that allows for displaying default values if the input is falsy.
 *
 * @usageNotes
 * ```
 * // In your Angular template:
 * {{ someValue | formatValue:defaultValue }}
 * ```
 *
 * @example
 * // In an Angular component:
 * const formattedValue = this.formatValuePipe.transform(someValue, defaultValue);
 */
@Pipe({ name: 'formatValue' })
export class FormatValuePipe implements PipeTransform {
  /**
   * Transform and format the input value.
   *
   * @param {string | number | null } [prop] - The value to be transformed.
   * @param {string | number } [defaultValue] - The default value if 'prop' is falsy.
   * @returns {string | number} If `prop` is falsy, returns the `defaultValue` or a dash (-) if no default is provided.
   */
  transform = (prop?: string | number | null, defaultValue?: string | number): string | number => {
    if (!prop) {
      return defaultValue ? defaultValue : '-';
    }

    return prop;
  };
}
