import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

import { Actor } from '../../models';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'actor' })
export class ActorStore extends Store<Actor> {
  constructor() {
    super({});
  }
}
