import { MeasurementSystem, UOMSymbol } from '../enums';
import { UOM } from '../models';

/**
 * Distance records for different measurement systems.
 *
 * Each entry in the `Distance` object corresponds to a specific measurement system (e.g., Metric, Imperial)
 * and contains default UOM symbols and their corresponding titles for distance measurement.
 *
 * @constant
 * @type {Record<MeasurementSystem, UOM>}
 */
export const Distance: Record<MeasurementSystem, UOM> = {
  [MeasurementSystem.METRIC]: {
    default: UOMSymbol.KILOMETERS,
    list: [
      { value: UOMSymbol.KILOMETERS, title: 'Kilometers' },
      { value: UOMSymbol.NAUTICAL_MILES, title: 'Nautical miles' },
    ],
  },
  [MeasurementSystem.IMPERIAL]: {
    default: UOMSymbol.MILES,
    list: [
      { value: UOMSymbol.MILES, title: 'Miles' },
      { value: UOMSymbol.NAUTICAL_MILES, title: 'Nautical miles' },
    ],
  },
};

/**
 * Weight records for different measurement systems.
 *
 * Each entry in the `Weight` object corresponds to a specific measurement system (e.g., Metric, Imperial)
 * and contains default UOM symbols and their corresponding titles.
 *
 * @constant
 * @type {Record<MeasurementSystem, UOM>}
 */
export const Weight: Record<MeasurementSystem, UOM> = {
  [MeasurementSystem.METRIC]: {
    default: UOMSymbol.TONS,
    list: [{ value: UOMSymbol.TONS, title: 'Tons' }],
  },
  [MeasurementSystem.IMPERIAL]: {
    default: UOMSymbol.IMPERIAL_TONS,
    list: [{ value: UOMSymbol.IMPERIAL_TONS, title: 'Imperial tons' }],
  },
};
