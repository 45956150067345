import { Pipe, PipeTransform } from '@angular/core';

import { permissionKeyToHumanValue } from '@core/utils/permission-utils';

@Pipe({ name: 'permissionKeyToHuman' })
export class PermissionKeyToHumanPipe implements PipeTransform {
  transform(value: string): string {
    return permissionKeyToHumanValue(value);
  }
}
