import { Exclude, Expose, Transform } from 'class-transformer';

import { IWarehouseHistory } from '@core/models';

import { CapitalizeFirstLetterPipe } from '../pipes';

@Exclude()
export class WarehouseHistory implements IWarehouseHistory {
  @Expose()
    createdAt!: string;

  @Expose()
    fullName!: string;

  @Expose()
    email!: string;

  @Expose()
  @Transform(({ value }) => new CapitalizeFirstLetterPipe().transform(value))
    description!: string;
}
