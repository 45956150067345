import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { first, Observable } from 'rxjs';

import { AuditLog, AuditLogFilter, Response, ServiceGet } from '../models';
import { ApiService, LoadingService, SnackbarService } from '../services';

const ENDPOINT = 'v1/logs';

@Injectable({ providedIn: 'root' })
export class AuditlogsService implements ServiceGet<AuditLog> {
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  get(id: string): Observable<AuditLog> {
    return this.api
      .get<AuditLog>(`${ENDPOINT}/${id}`)
      .pipe(first(), this.loader.set('audit-get'), this.snack.operator());
  }

  filters(): Observable<AuditLogFilter> {
    return this.api.get<AuditLogFilter>(`${ENDPOINT}/filters`).pipe(first());
  }

  list(
    page: number,
    limit: number,
    sort: string | null = null,
    order: string | null = null,
    filters: object = {},
  ): Observable<Response<AuditLog[]>> {
    const data = { page, limit, ...(sort && { sort }), ...(order && { order }), ...(filters && { ...filters }) };
    const params = new HttpParams({ fromObject: data });

    return this.api
      .get<Response<AuditLog[]>>(`${ENDPOINT}`, { params })
      .pipe(first(), this.loader.set('audit-list'), this.snack.operator());
  }
}
