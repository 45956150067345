import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { Observable, tap, throwError } from 'rxjs';

import { SnackbarComponent } from '@shared/snackbar';

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  constructor(private snackbar: MatSnackBar) {}

  success(message: string): void {
    this.#open('check_circle_outline', 'success', message, 1000);
  }

  error(message: string): void {
    this.#open('error_outline', 'warn', message);
  }

  operator<T>(successMessage: string | null = null): (source: Observable<T>) => Observable<T> {
    return (source: Observable<T>) => {
      return source.pipe(
        tap({
          complete: () => {
            if (successMessage) {
              this.success(successMessage);
            }
          },
          error: (error) => {
            const errorMessage = error.error.message ? error.error.message : error.statusText;
            this.error(errorMessage);

            return throwError(() => errorMessage);
          },
        }),
      );
    };
  }

  #open(icon: string, color: string, message: string, duration = 3000): void {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: { icon, color, message },
      duration,
    });
  }
}
