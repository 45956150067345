import { Exclude, Expose } from 'class-transformer';

import { warehouseReportingStatusMap, warehouseStatusMap } from '@core/constants';
import { WarehouseReportingStatus, WarehouseStatus } from '@core/enums';
import { Warehouse } from '@core/models';
import { Badge } from '@shared/badge';

@Exclude()
export class DashboardWarehouse implements Partial<Warehouse> {
  @Expose({ name: 'id' })
    warehouseId!: string;

  @Expose()
    name!: string;

  @Expose()
    plantCode!: string;

  @Expose()
    contacts!: string;

  @Expose()
    updatedAt!: string;

  @Expose()
    status!: WarehouseStatus;

  @Expose()
    reportingStatus!: WarehouseReportingStatus;

  get statusBadge(): Badge<WarehouseStatus> {
    return this.status ? warehouseStatusMap[this.status] : warehouseStatusMap[WarehouseStatus.NEW];
  }

  get reportingStatusBadge(): Badge<WarehouseReportingStatus> {
    return this.reportingStatus
      ? warehouseReportingStatusMap[this.reportingStatus]
      : warehouseReportingStatusMap[WarehouseReportingStatus.NOT_REPORTED];
  }
}
