export enum PreferencesLoaderKey {
  UPDATE_PREFERENCE = 'save',
}

export enum Channel {
  EMAIL = 'email',
  BELL = 'bell',
  PUSH = 'push',
  SMS = 'sms',
}
