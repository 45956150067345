import { Injectable } from '@angular/core';

import { first, Observable } from 'rxjs';

import { BellNotifications } from '../models';
import { ApiService } from '../services';

const endpoint = 'v1/notifications';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private api: ApiService) {}

  list(): Observable<BellNotifications> {
    return this.api.get<BellNotifications>(endpoint).pipe(first());
  }

  markRead(id: string): Observable<never> {
    return this.api.patch<never, void>(`${endpoint}/read/${id}`).pipe(first());
  }

  markAllRead(): Observable<never> {
    return this.api.patch<never, void>(`${endpoint}/readAll`).pipe(first());
  }

  clearAll(): Observable<never> {
    return this.api.patch<never, void>(`${endpoint}/clear`).pipe(first());
  }
}
