export enum AlertKeyCondition {
  RECEIVER = 'receiverId',
  MESSAGE_TYPE = 'messageType',
}

export enum AlertLoaderKey {
  GET_ALERT = 'get-alert',
  LIST_ALERTS = 'list-alerts',
  CREATE_ALERT = 'create-alert',
  UPDATE_ALERT = 'update-alert',
  DELETE_ALERT = 'delete-alert',
}
