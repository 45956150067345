import { WebSocketSubject } from 'rxjs/webSocket';

export interface WebSocketConnection {
  room: string;
  connection: WebSocketSubject<unknown>;
}

export interface WebsocketHeaders {
  token: string;
  room: string;
  userId?: string;
}
