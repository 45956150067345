import { Injectable } from '@angular/core';

import { first, Observable } from 'rxjs';

import { PreferencesLoaderKey } from '../enums';
import { Preferences, PreferencesDto } from '../models';
import { ApiService, LoadingService, SnackbarService } from '../services';

const ENDPOINT = 'v1/preferences';

@Injectable({ providedIn: 'root' })
export class PreferenceService {
  constructor(
    private api: ApiService,
    private loader: LoadingService,
    private snack: SnackbarService,
  ) {}

  getPreferences(): Observable<Preferences> {
    return this.api.get<Preferences>(`${ENDPOINT}`).pipe(first(), this.loader.set('get'), this.snack.operator());
  }

  updatePreference(payload: PreferencesDto): Observable<Preferences> {
    return this.api
      .patch<Preferences, PreferencesDto>(`${ENDPOINT}`, payload)
      .pipe(
        first(),
        this.loader.set(PreferencesLoaderKey.UPDATE_PREFERENCE),
        this.snack.operator('Preference updated'),
      );
  }
}
