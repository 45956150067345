import { Observable } from 'rxjs';

export interface ServiceGet<Returned = void> {
  get(id?: string): Observable<Returned>;
}

export interface ServiceList<Returned = void, Arguments = void> {
  list(options?: Arguments): Observable<Returned>;
}

export interface ServiceCreate<Returned = void, Payload = void> {
  create(payload: Payload): Observable<Returned>;
}

export interface ServiceUpdate<Returned = void, Payload = void> {
  update(id: string, payload: Payload): Observable<Returned>;
}

export interface ServiceDelete<Returned = void> {
  delete(id: string): Observable<Returned>;
}

export interface IService extends ServiceGet, ServiceList, ServiceCreate, ServiceUpdate, ServiceDelete {}
