import { IStatusCode } from '../models';

export const StatusCodes: IStatusCode = {
  inbound_stored: {
    short: 'Inbound message stored',
    long: 'The Hub has received an inbound message and it has been stored for further processing.',
  },
  custom_function_executed: {
    short: 'Custom function executed',
    long: 'A custom function has been found and executed for the partner.',
  },
  custom_function_error: {
    short: 'Partner function execution failed',
    long: 'Partner function execution failed. Please contact support for assistance.',
  },
  control_data_found: {
    short: 'Control data found in the message',
    long: 'Sender, receiver, message type and unique ID found and validated in the message.',
  },
  parser_not_found: {
    short: 'Parser setting not found in configuration',
    long: 'Please contact support for assistance.',
  },
  filename_error: {
    short: 'Filename error',
    long: 'Control data not found in the provided filename. Filename syntax must be ' +
      '{MESSAGETYPE}-{SENDER}-{RECEIVER}-{UNIQUEID}.{EXTENSION}.',
  },
  xml_error: {
    short: 'XML error',
    long: 'Control data not found in the provided XML file.',
  },
  business_rule_found: {
    short: 'Business rules found',
    long: 'Business rules were found in the configuration for the partner. Actions to be executed:',
  },
  business_rule_not_found: {
    short: 'Business rules not found',
    long: 'Business rules were found in the configuration.',
  },
  emission_stored: {
    short: 'JSON standard sent for emission calculation',
    long: 'Emission calculation was enabled for this file and it was sent to the emission calculator.',
  },
  inbound_to_standard_translated: {
    short: 'Inbound format transformed to Veratrak standard',
    long: 'Inbound message was successfully transformed to the Veratrak JSON standard.',
  },
  translation_error: {
    short: 'Translation error',
    long: 'The translation was not successful. Details:',
  },
  standard_stored: {
    short: 'Standard stored',
    long: 'The standard message was stored for further processing.',
  },
  inbound_to_staging_translated: {
    short: 'Inbound format transformed and stored',
    long: 'Inbound format is successfully processed and stored temporarily for future processing.',
  },
  standard_to_outbound_translated: {
    short: 'Veratrak standard transformed to outbound format',
    long: 'The Veratrak standard format file was successfully transformed to the appropriate recipient file format.',
  },
  outbound_stored: {
    short: 'Outbound format stored',
    long: 'The outbound format file was stored in the Hub.',
  },
  outbound_sent: {
    short: 'Outbound message sent',
    long: 'The Hub has successfully sent the outbound format file to the appropriate destination.',
  },
  outbound_integration_not_found: {
    short: 'Outbound integration not found',
    long: 'The outbound integration was not found. Please contact support for assistance.',
  },
  outbound_integration_error: {
    short: 'Outbound integration error',
    long: 'The outbound integration threw an error. Please contact support for assistance. Details:',
  }
};
