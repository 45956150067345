import { Injectable } from '@angular/core';

import { Query } from '@datorama/akita';

import { ActorStore } from './actor.store';
import { Actor } from '../../models';

@Injectable({ providedIn: 'root' })
export class ActorQuery extends Query<Actor> {
  constructor(protected override store: ActorStore) {
    super(store);
  }
}
