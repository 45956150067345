/**
 * @note
 * Code 1005 should not be used to reconnect.
 */
export const CLOSE_CODES = [
  1001, // The endpoint is going away (AWS timeout)
  1006, // Indicates that a connection was closed abnormally (closed your laptop)
];

export const BELL_ROOM = 'bell';
