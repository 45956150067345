import { Component, Input, OnInit } from '@angular/core';

import { BadgeType } from './enums';
import { Badge } from './models';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit {
  @Input() badgeData?: Badge<string>;

  ngOnInit(): void {
    this.#initBadgeData();
  }

  #initBadgeData(): void {
    const background = this.badgeData?.background ? `background-color: ${this.badgeData.background}; ` : '';
    const color = this.badgeData?.color ? `color: ${this.badgeData?.color}; ` : '';
    const badgeStyle = `${background}${color}`;

    this.badgeData = {
      ...this.badgeData,
      key: this.badgeData?.key ?? 'key',
      value: this.badgeData?.value ?? '',
      type: this.badgeData?.type ?? BadgeType.DISABLED,
      badgeStyle,
    };
  }
}
