export enum RoleLoaderKey {
  GET_ROLE = 'get-role',
  LIST_ROLES = 'list-roles',
  CREATE_ROLE = 'create-role',
  UPDATE_ROLE = 'update-role',
  DELETE_ROLE = 'delete-role',
}

export enum RoleType {
  CUSTOM = 1,
  ADMIN = 2,
}
