import { ComponentType } from '@angular/cdk/overlay';

import { Transport1DialogComponent } from '@feature/templates/transport1';
import { Transport4DialogComponent } from '@feature/templates/transport4';
import { Warehouse1DialogComponent } from '@feature/templates/warehouse1';
import { Warehouse2DialogComponent } from '@feature/templates/warehouse2';
import { Badge, BadgeType } from '@shared/badge';

import { DataCollectionTemplateId, WarehouseUploadStatus } from '../enums';
import { SelectOption, TemplateComponents } from '../models';

export const MAX_FILE_SIZE = 5000000;

/**
 * Data Collection History Status Map.
 *
 * @constant
 * @type {Record<UploadStatus, Badge<UploadStatus>>}
 */
export const dataCollectionHistoryStatusMap: Record<WarehouseUploadStatus, Badge<WarehouseUploadStatus>> = {
  [WarehouseUploadStatus.SUCCESS]: {
    key: WarehouseUploadStatus.SUCCESS,
    value: 'Success',
    type: BadgeType.SUCCESS,
  },
  [WarehouseUploadStatus.PROCESSING]: {
    key: WarehouseUploadStatus.PROCESSING,
    value: 'Processing',
    type: BadgeType.PROGRESS,
  },
  [WarehouseUploadStatus.CALCULATION_FAILED]: {
    key: WarehouseUploadStatus.CALCULATION_FAILED,
    value: 'Calculation Failed',
    type: BadgeType.ERROR,
  },
  [WarehouseUploadStatus.UPLOAD_FAILED]: {
    key: WarehouseUploadStatus.UPLOAD_FAILED,
    value: 'Upload Failed',
    type: BadgeType.ERROR,
  },
};

/**
 * List of Data Collection Statuses.
 *
 * @constant
 * @type {SelectOption<UploadStatus>[]}
 * @example [{key: 'SUCCESS', value: 'Success'}]
 */
export const dataCollectionStatusList: SelectOption<WarehouseUploadStatus>[] = Object
  .values(dataCollectionHistoryStatusMap).map(({ key, value }) => ({ key, value }));

/**
 * Mapping of template component IDs to their respective component types.
 *
 * This mapping includes references to specific Angular components associated with
 * certain template names.
 *
 * @constant
 * @type {{ [key: string]: ComponentType<TemplateComponents> }}
 * @see {@link https://bit.ly/3QDDzA4 Emission Templates Documentation}
 */
export const templateComponents: { [key: string]: ComponentType<TemplateComponents> } = {
  [DataCollectionTemplateId.TRANSPORT_1]: Transport1DialogComponent,
  [DataCollectionTemplateId.TRANSPORT_4]: Transport4DialogComponent,
  [DataCollectionTemplateId.WAREHOUSE_1]: Warehouse1DialogComponent,
  [DataCollectionTemplateId.WAREHOUSE_2]: Warehouse2DialogComponent,
};
