import { Exclude, Expose, Transform } from 'class-transformer';

import { ChartLegend, ChartOptions, EmissionDashboardMethods } from '@core/models';

@Exclude()
export class DashboardMethodsMetrics implements EmissionDashboardMethods {
  @Expose()
  @Transform(({ value = 0 }) => Math.round(value))
    air!: number;

  @Expose()
  @Transform(({ value = 0 }) => Math.round(value))
    sea!: number;

  @Expose()
  @Transform(({ value = 0 }) => Math.round(value))
    surface!: number;

  get totalTransport(): number {
    return this.surface + this.sea + this.air;
  }

  get airTransportPercentage(): string {
    return this.totalTransport ? this.#getPercent(this.air, this.totalTransport) : '0%';
  }

  get seaTransportPercentage(): string {
    return this.totalTransport ? this.#getPercent(this.sea, this.totalTransport) : '0%';
  }

  get roadTransportPercentage(): string {
    return this.totalTransport ? this.#getPercent(this.surface, this.totalTransport) : '0%';
  }

  get transportChartOptions(): ChartOptions<string> {
    const series = [this.air, this.sea, this.surface];
    const percents = [this.airTransportPercentage, this.seaTransportPercentage, this.roadTransportPercentage];
    const labels = ['Air', 'Sea', 'Road'];
    const colors = ['#008FFB', '#FEB019', '#775DD0'];

    const legend: ChartLegend<string>[] = this.#getLegend<string>(percents, labels, colors);

    return { series, labels, colors, legend };
  }

  #getLegend<T>(values: T[], labels: string[], colors: string[]): ChartLegend<T>[] {
    return values.map((value, index) => ({
      name: labels[index],
      color: colors[index],
      value,
    }));
  }

  #getPercent(value: number, total: number): string {
    return `${Math.round((value / total) * 100)}%`;
  }
}
