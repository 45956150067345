import { Exclude, Expose, Transform } from 'class-transformer';

import { ChartLegend, ChartOptions, ReportedWarehouses } from '@core/models';

@Exclude()
export class DashboardReportingMetrics implements ReportedWarehouses {
  @Expose()
  @Transform(({ value = 0 }) => Math.round(value))
    reported!: number;

  @Expose()
  @Transform(({ value = 0 }) => Math.round(value))
    partiallyReported!: number;

  @Expose()
  @Transform(({ value = 0 }) => Math.round(value))
    notReported!: number;

  get totalReporting(): number {
    return this.reported + this.notReported + this.partiallyReported;
  }

  get reportingChartOptions(): ChartOptions<number> {
    const series = [this.reported, this.partiallyReported, this.notReported];
    const labels = ['Reported', 'Partially Reported', 'Not Reported'];
    const colors = ['#4CAF50', '#FFC107', '#E0E0E0'];

    const legend: ChartLegend<number>[] = this.#getLegend<number>(series, labels, colors);

    return { series, labels, colors, legend };
  }

  #getLegend<T>(values: T[], labels: string[], colors: string[]): ChartLegend<T>[] {
    return values.map((value, index) => ({
      name: labels[index],
      color: colors[index],
      value,
    }));
  }
}
