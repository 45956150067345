import { RoleType } from '../enums';

export interface Role {
  id: string;
  partnerId: string;
  name: string;
  description: string;
  type: RoleType;
  permissions: string[];
  createdAt: string;
  updatedAt: string | null;
}
