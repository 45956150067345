import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export const atLeastOneFieldValidator = (fieldNames: string[]): ValidatorFn => {
  return (control: AbstractControl): {[key: string]: unknown} | null => {
    const group = control as FormGroup;
    const hasAtLeastOneValue = fieldNames.some(fieldName => {
      const value = group.get(fieldName)?.value;
      return value && value !== null && value !== '';
    });
    return hasAtLeastOneValue ? null : { noValues: 'At least one field must have a value' };
  };
};
