import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';

import { map, Subscription } from 'rxjs';

import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { VeratrakAuthService } from '@core/services';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  subscriptions: Subscription[] = [];
  logoutCalled = false;
  constructor(
    private gtm: GoogleTagManagerService,
    private authService: VeratrakAuthService,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.gtm.addGtmToDom();
    this.handleLogoutUrl();
  }

  handleLogoutUrl(): void {
    this.route.url.pipe(map((segments: UrlSegment[]) => segments.join(''))).subscribe((url: string) => {
      if (url.includes('logout')) {
        this.authService.logout();
      }
    });
  }
}
