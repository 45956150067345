import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';

import { ActorGuard } from '@core/guards';

import { AppComponent } from './app.component';
import { NotfoundComponent } from './layout/auth-layout/notfound';

const routes: Route[] = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    data: { breadcrumb: null },
  },
  {
    path: '',
    loadChildren: () => import('./layout/auth-layout/auth-layout.module').then((module) => module.AuthLayoutModule),
  },
  {
    path: '',
    loadChildren: () => import('./layout/app-layout/app-layout.module').then((module) => module.AppLayoutModule),
    canActivate: [AuthGuard, ActorGuard],
  },
  {
    path: 'admin',
    loadChildren: () => import('./layout/admin-layout/admin-layout.module').then((module) => module.AdminLayoutModule),
    canActivate: [AuthGuard, ActorGuard],
  },
  {
    path: 'error',
    loadChildren: () => import('./feature/error/error.module').then((module) => module.ErrorModule),
  },
  {
    path: 'logout',
    component: AppComponent,
  },
  {
    path: '**',
    component: NotfoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRouting {}
