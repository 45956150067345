/**
 * Accessor function for sorting strings in table data.
 *
 * @function
 * @template T - The type of the data being sorted.
 * @param {T} data - The data object to be accessed for sorting.
 * @param {string} sortHeaderId - The identifier for the header used in sorting.
 * @returns {string} - Returns a string for sorting purposes.
 */
export const stringSortingDataAccessor = <T>(data: T, sortHeaderId: string): string => {
  const dataToMap = data[sortHeaderId as keyof T];

  if (typeof dataToMap === 'string') {
    return dataToMap.toLocaleLowerCase();
  }

  return dataToMap as string;
};
