import { SelectOption } from '../models';

/**
 * Transform a string date to ISO format.
 *
 * @function
 * @param {string} stringDate - The input string representing a date.
 * @param {boolean} [end=false] - If true, adjust the date to the end of the day.
 * @returns {string} -  The transformed date in ISO format.
 * @example "2023-01-02T00:00:00.000Z"
 */
export const transformDate = (stringDate: string, end = false): string => {
  const targetDate = new Date(stringDate);

  if (end) {
    targetDate.setDate(targetDate.getDate() + 1);
  }

  return targetDate.toISOString();
};

/**
 * Get the list of month names.
 *
 * @function
 * @param {string} [locale='en-US'] - The locale to use for formatting the month names.
 * @returns {string[]} - An array of month names.
 * @example [ 'January', 'February', 'March', ... ]
 */
export const getLocaleMonthNames = (locale = 'en-US'): string[] => {
  return Array.from({ length: 12 }, (_, monthIndex) => {
    const date = new Date(2000, monthIndex, 1);

    return date.toLocaleString(locale, { month: 'long' });
  });
};

/**
 * Get a list of localized month names.
 *
 * @function
 * @param {number[]} [monthIndexList=[]] - The list of month indices.
 * @param {string} [locale='en-US'] - The locale for which to retrieve the month names.
 * @returns {SelectOption<string>[]} - A list of localized month names.
 * @example [{ key: '0', value: 'January' }, ...]
 */
export const getSelectedLocaleMonthList = (monthIndexList: number[] = [], locale = 'en-US'): SelectOption<string>[] => {
  const localeMonthNames = getLocaleMonthNames(locale);

  return monthIndexList.map((monthIndex) => ({ key: monthIndex.toString(), value: localeMonthNames[monthIndex] }));
};

/**
 * Get the date a specified number of months ago from the current date.
 *
 * @function
 * @param {number} monthsAgo - The number of months to subtract from the current date.
 * @returns {Date} - The date representing 'monthsAgo' months in the past.
 */
export const getDateMonthsAgo = (monthsAgo: number): Date => {
  const currentDate = new Date();

  return new Date(currentDate.getFullYear(), currentDate.getMonth() - monthsAgo, currentDate.getDate());
};

/**
 * Get the formatted string representation of the first day of a specified month and year.
 *
 * @function
 * @param {number} year - The year (e.g., 2023).
 * @param {number} month - The month index (0 for January, 1 for February, etc.).
 * @returns {string} - A formatted string representing the first day of the specified month and year in UTC
 * @example "2023-01-01T00:00:00.000Z"
 */
export const getFirstDayOfMonth = (year: number, month: number): string => {
  const inputDate = new Date(year, month);
  const utcDate = new Date(Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), 1));
  return utcDate.toISOString();
};
