import { Partner } from '@core/models/partner';

export enum TransactionStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum TransactionSource {
  MANUAL = 'manual',
  AS2 = 'as2',
  SFTP = 'sftp',
  FTPS = 'ftps',
}

export interface Transaction {
  id: string;
  parentId: string | null;
  filename: string;
  filesize: number;
  sender: Partner,
  receiver: Partner | null,
  messageType: string | null;
  status: TransactionStatus;
  duplicateId: string | null;
  source: string;
  meta: object | null;
  createdAt: string;
}

export interface TransactionStep {
  id: string;
  createdAt: Date;
  transactionId: string;
  s3Ref: unknown;
  expiryAt?: Date;
  status: TransactionStatus;
  statusCode: string;
  error?: unknown;
}

export interface TransactionUI {
  id: string;
  createdAt: string;
  duplicateId: string | null;
  filename: string;
  messageType: string | null;
  original: object;
  parentId: string | null;
  receiverId: string | null;
  senderId: string;
  source: string;
  status: string;
  senderName: string;
  receiverName: string;
  statusCode: string;
}

export interface PaginatedTransactions {
  records: Transaction[];
  _meta: { total: number };
}

export interface S3Ref {
  bucket: string;
  prefix: string;
  key: string;
  eTag: string;
  type: string;
}

export interface TransactionMetrics {
  total: number;
  totalMonth: number;
  errorMonth: number;
  errorTotal: number;
}

export interface TransactionFilters {
  createdAt: {
    min?: Date;
    max?: Date;
  };
  senders: Record<string, string>[];
  receivers: Record<string, string>[];
  types: string[];
}
