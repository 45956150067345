/**
 * Check if a string array contains a specified filter string.
 *
 * @function
 * @param {string[]} dataArray - The array of strings to be checked.
 * @param {string} filter - The filter string to search for within the array.
 * @returns {boolean} - Returns true if the filter string is found within the array; otherwise, false.
 *
 * @see {@link https://bit.ly/3GFV9i5}
 */
export const filterPredicate = (dataArray: string[], filter: string): boolean => {
  const dataString = dataArray
    .filter((arrayItem) => arrayItem)
    .join('◬')
    .toLowerCase();

  const transformedFilter = filter.trim().toLowerCase();

  return dataString.indexOf(transformedFilter) !== -1;
};
