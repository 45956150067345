import { PERMISSIONS } from '../constants';
import { Permission } from '../enums';
import { SelectOption } from '../models';

/**
 * Capitalize first letter of the word.
 *
 * @function
 * @param {string} word - word to capitalize.
 * @returns {string} - string with capitalized first letter.
 * @example - "users" to "Users".
 */
export const capitalizeFirstLetter = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

/**
 * Convert permission string to human-readable value.
 *
 * @function
 * @param {string} permissionString - raw permission string.
 * @returns {string} - human-readable value.
 * @example - "users:create" to "Users : Create".
 */
const getFormattedPermission = (permissionString: string): string => {
  const [key, value] = permissionString.split(':');

  const formattedKey = key.split('-').map(capitalizeFirstLetter).join(' ');
  const formattedValue = capitalizeFirstLetter(value);

  return `${formattedKey} : ${formattedValue}`;
};

/**
 * Check if permission exists and return human-readable value.
 *
 * @function
 * @param {string} permissionKey - raw permission key.
 * @returns {string} - human-readable value.
 * @example - "users:create" to "Users : Create".
 */
export const permissionKeyToHumanValue = (permissionKey: string): string => {
  return Object.values(Permission).includes(permissionKey as Permission)
    ? getFormattedPermission(permissionKey)
    : 'Unknown permission';
};

/**
 * Get validated permission keys from input permission keys.
 *
 * @function
 * @param {Permission[]} permissions - input permission keys list.
 * @returns {Permission[]} - validated permission keys list.
 */
export const getValidatedPermissionKeyList = (permissions: Permission[]): Permission[] => {
  const validPermissionKeysList = Object.values(Permission);

  return permissions.filter((permission) => validPermissionKeysList.includes(permission));
};

/**
 * Map of Permissions.
 *
 * @constant
 * @type {Record<Permission, SelectOption<Permission>>}
 */
export const permissionMap: Record<Permission, SelectOption<Permission>> = PERMISSIONS.reduce(
  (acc, { key, desc }) => ({
    ...acc,
    [key]: { key, value: permissionKeyToHumanValue(key), desc },
  }),
  {} as Record<Permission, SelectOption<Permission>>,
);
