export enum AuditLogEvent {
  CREATED = 'created',
  DELETED = 'deleted',
  DOWNLOADED = 'downloaded',
  PREVIEWED = 'previewed',
  UPDATED = 'updated',
}

export enum AuditLogCategory {
  USER = 'user',
  ROLE = 'role',
  GROUP = 'group',
  ALERT = 'alert',
  WAREHOUSE = 'warehouse',
  WAREHOUSE_CONTACT = 'warehouse-contact',
  TEMPLATE = 'template',
}
