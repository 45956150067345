import { Injectable } from '@angular/core';

import { Observable, switchMap, take } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { AuthService } from '@auth0/auth0-angular';

import { environment } from '@src/environments/environment';

import { BELL_ROOM, CLOSE_CODES } from '../constants';
import { WebSocketConnection, WebsocketHeaders } from '../models';
import { ActorService } from '../services';

@Injectable({ providedIn: 'root' })
export class SocketService {
  #connections: WebSocketConnection[] = [];
  readonly #userId = this.actorService.get().id;

  constructor(
    private actorService: ActorService,
    private authService: AuthService,
  ) {}

  connect(room: string): Observable<unknown> {
    return this.#getToken().pipe(switchMap((token: string) => this.createConnection(room, token)));
  }

  createConnection(room: string, token: string): WebSocketSubject<unknown> {
    const headers: WebsocketHeaders = { room, token };

    if (room === BELL_ROOM) {
      headers.userId = this.#userId;
    }

    const connection = webSocket({
      url: this.#buildUrlWithHeaders(environment.socketHost, headers),
      closeObserver: {
        next: (closeEvent: CloseEvent) => {
          if (CLOSE_CODES.includes(closeEvent.code)) {
            // reconnect if connection is abruptly closed
            this.connect(room);
          }
        },
      },
    });

    this.#connections.push({ room: room, connection: connection });

    return connection;
  }

  disconnect(room: string): void {
    const index = this.#connections.findIndex((connection) => connection.room === room);

    if (index !== -1) {
      this.#connections[index].connection.complete();
      this.#connections.splice(index, 1);
    }
  }

  #getToken(): Observable<string> {
    return this.authService.getAccessTokenSilently().pipe(take(1));
  }

  #buildUrlWithHeaders(baseUrl: string, headers: WebsocketHeaders): string {
    const headerStrings = Object.entries(headers).map(
      ([key, value]) => `${key}=${encodeURIComponent(value as string)}`,
    );

    return `${baseUrl}?${headerStrings.join('&')}`;
  }
}
