import { SelectOption } from '../models';

export const avatarColorList: Omit<SelectOption<string>, 'value'>[] = [
  { key: '#004C6D', selected: false },
  { key: '#0B83D9', selected: false },
  { key: '#9E5BD9', selected: false },
  { key: '#D94182', selected: false },
  { key: '#E36A00', selected: false },
  { key: '#BF7000', selected: false },
  { key: '#2DA608', selected: false },
  { key: '#06A893', selected: false },
  { key: '#C9806B', selected: false },
  { key: '#465BB3', selected: false },
  { key: '#990099', selected: false },
  { key: '#C7AF14', selected: false },
  { key: '#566614', selected: false },
  { key: '#D92B2B', selected: false },
  { key: '#525266', selected: false },
  { key: '#000000', selected: false },
];
