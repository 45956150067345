import { Exclude, Expose } from 'class-transformer';

import { Avatar, IUserWithPartner, TradingPartner, UserTypes } from '@core/models';
import { capitalizeFirstLetter } from '@core/utils';

@Exclude()
export class UserWithPartner implements IUserWithPartner {
  @Expose()
    name!: string;

  @Expose()
    email!: string;

  @Expose()
    type!: UserTypes;

  @Expose()
    partner!: TradingPartner | null;

  @Expose()
    createdAt!: string;

  @Expose()
    registeredAt!: string;

  @Expose()
    id!: string;

  @Expose()
    avatar!: Avatar;

  @Expose()
    updatedAt!: string | null;

  get tradingPartnerName(): string {
    return this.partner?.name ?? '';
  }

  get userType(): string {
    return capitalizeFirstLetter(this.type);
  }
}
