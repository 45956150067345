export enum GroupLoaderKey {
  GET_USER_GROUP = 'get-user-group',
  LIST_USER_GROUPS = 'list-user-groups',
  CREATE_USER_GROUP = 'create-user-group',
  UPDATE_USER_GROUP = 'update-user-group',
  DELETE_USER_GROUP = 'delete-user-group',
  DETACH_GROUP_ROLE = 'detach-group-role',
  DETACH_GROUP_USER = 'detach-group-user',
  ADD_GROUP_ROLES = 'add-group-roles',
  ADD_GROUP_USERS = 'add-group-users',
}
