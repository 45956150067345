import { Injectable } from '@angular/core';

import { first, Observable } from 'rxjs';

import { ServiceList, TradingPartner } from '../../models';
import { ApiService } from '../../services';

const ENDPOINT = 'v1/partners';

@Injectable({ providedIn: 'root' })
export class TradingPartnersApiService implements ServiceList<TradingPartner[]> {
  constructor(private api: ApiService) {
  }

  list(): Observable<TradingPartner[]> {
    return this.api.get<TradingPartner[]>(ENDPOINT).pipe(first());
  }

  listMessageTypes(): Observable<string[]> {
    return this.api.get<string[]>(`${ENDPOINT}/types`).pipe(first());
  }
}
