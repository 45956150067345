import { Pipe, PipeTransform } from '@angular/core';
import { TransactionStatus } from '@core/models';
@Pipe({
  name: 'transactionStatus'
})
export class TransactionStatusPipe implements PipeTransform {

  transform(status: TransactionStatus): string {
    switch (status) {
      case TransactionStatus.SUCCESS:
        return 'Success';
      case TransactionStatus.FAILED:
        return 'Failed';
      case TransactionStatus.PENDING:
        return 'In Progress';
      default:
        return status;
    }
  }
}
