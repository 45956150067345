import { Pipe, PipeTransform } from '@angular/core';

import { UOMSymbol } from '@core/enums/uom.enum';

export type FormatTonnesOutput = 'value' | 'unit';

/**
 * A pipe to format tonnes into different units (Tons, Kilotons, Megatons, Gigatons).
 *
 * @usageNotes
 * ```
 * // In Angular template:
 * {{ someValue | formatTons:output }}
 * ```
 *
 * @example
 * // In an Angular component:
 * const formattedTons = this.formatTonsPipe.transform(someValue, output);
 */
@Pipe({ name: 'formatTons' })
export class FormatTonsPipe implements PipeTransform {
  protected readonly kiloton = 1000;
  protected readonly megaton = 1000000;
  protected readonly gigaton = 1000000000;

  /**
   * Transforms the input value to the specified output format.
   * @param {number} value - The value in tonnes to be formatted.
   * @param {FormatTonnesOutput} [output] - The output format ('value' or 'unit').
   * @returns {string} - The formatted value, unit or both value and unit of the tonnes.
   * @example "100 t", "1.00 kt", ...
   */
  transform(value: number, output?: FormatTonnesOutput): string {
    const tons = value ? value : 0;

    if (output) {
      return output === 'value' ? this.#getTonnesValue(tons) : this.#getTonnesUnit(tons);
    }

    return this.#getTonnesValueUnit(tons);
  }

  #getTonnesValue(value: number): string {
    if (value >= this.gigaton) {
      return this.#getConvertedValue(value, this.gigaton);
    }

    if (value >= this.megaton) {
      return this.#getConvertedValue(value, this.megaton);
    }

    if (value >= this.kiloton) {
      return this.#getConvertedValue(value, this.kiloton);
    }

    return `${value}`;
  }

  #getTonnesUnit(value: number): string {
    if (value >= this.gigaton) {
      return UOMSymbol.GIGATONS;
    }

    if (value >= this.megaton) {
      return UOMSymbol.MEGATONS;
    }

    if (value >= this.kiloton) {
      return UOMSymbol.KILOTONS;
    }

    return UOMSymbol.TONS;
  }

  #getTonnesValueUnit(value: number): string {
    return `${this.#getTonnesValue(value)} ${this.#getTonnesUnit(value)}`;
  }

  #getConvertedValue(value: number, tonnesEquivalent: number): string {
    return (value / tonnesEquivalent).toFixed(2);
  }
}
