import { IUser, Role } from '../models';

export interface Group {
  id: string;
  name: string;
  partnerId: string;
  description: string;
  users: IUser[];
  roles: Role[];
  createdAt: string;
  updatedAt: string | null;
}
