import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@src/environments/environment';

/**
 * @note
 * API abstraction over HttpClient.
 */
@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(private http: HttpClient) {}
  get<T>(endpoint: string, options?: object): Observable<T> {
    return this.http.get<T>(`${environment.apiHost}/${endpoint}`, options);
  }

  post<T, P>(endpoint: string, payload: P): Observable<T> {
    return this.http.post<T>(`${environment.apiHost}/${endpoint}`, payload);
  }

  put<T, P>(endpoint: string, id: string, payload: P): Observable<T> {
    return this.http.put<T>(`${environment.apiHost}/${endpoint}/${id}`, payload);
  }

  patch<T, P>(endpoint: string, payload?: P): Observable<T> {
    return this.http.patch<T>(`${environment.apiHost}/${endpoint}`, payload);
  }

  delete<T>(endpoint: string, id: string): Observable<T> {
    return this.http.delete<T>(`${environment.apiHost}/${endpoint}/${id}`);
  }

  detach<T>(endpoint: string): Observable<T> {
    return this.http.delete<T>(`${environment.apiHost}/${endpoint}`);
  }
}
