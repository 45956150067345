export const Placeholders = {
  DeletedUser: {
    short: 'User',
    long: 'deleted user',
  },
  SomethingWentWrong: {
    short: 'Something went wrong.',
    long: 'Something went wrong. Please reload the page and try again.',
  },
};
