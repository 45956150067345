import { Pipe, PipeTransform } from '@angular/core';

import { AlertEvents } from '@core/constants';
import { AlertEvent } from '@core/models';

/**
 * Represents an Angular Pipe to transform a string value into an alert event name.
 *
 * @usageNotes
 * In Angular template:
 * ```{{ someString | alertEvent }}```
 *
 * In Angular component:
 * ```const alertEvent = this.alertEventPipe.transform(someString);```
 */
@Pipe({ name: 'alertEvent' })
export class AlertEventPipe implements PipeTransform {
  /**
   * Transforms the input string value into an alert event name.
   *
   * @function
   * @param {string} value - The string value representing the alert event key.
   * @returns {string} - The corresponding alert event name or a dash ('-') if not found.
   */
  transform(value: string): string {
    const alert = AlertEvents.find((alert: AlertEvent) => alert.key === value);

    return alert?.name ? alert.name : '-';
  }
}
