import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LoadingState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'loading' })
export class LoadingStore extends Store<LoadingState> {
  constructor() {
    super({});
  }
}
