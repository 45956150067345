export interface Response<T> {
  data: T[];
  status: number;
  total: number;
}

export interface RequestParams<T> {
  limit: number | null;
  sort: keyof T;
  order: string;
  page?: number;
}

export interface SelectOption<T> {
  key: T;
  value: string;
  desc?: string;
  selected?: boolean;
  category?: string;
}

export interface ExtraParams<T> {
  status?: T;
  search?: string;
  startDate?: string;
  endDate?: string;
}

export interface ChartLegend<T> {
  name: string;
  color: string;
  value: T;
}

export interface ChartOptions<T> {
  series: number[];
  labels: string[];
  colors: string[];
  legend: ChartLegend<T>[];
}
