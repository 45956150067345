/**
 * Exclude empty fields from the object.
 *
 * @function
 * @param {Record<string, unknown>} inputObject - input object.
 * @returns {Record<string, unknown>} - object with removed empty fields.
 */
export const removeEmptyFieldsFromObject = <T extends object>(inputObject: T, optionalFields: string[] = []): T =>
  Object.fromEntries(
    Object.entries(inputObject).filter(([field, value]) => {
      if (optionalFields.includes(field)) {
        return true;
      }

      return value !== null && value !== undefined && value !== false && value !== '';
    }),
  ) as T;
