import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import * as Sentry from '@sentry/angular-ivy';

import { CidInterceptor, ErrorInterceptor } from '@core/interceptors';
import { environment } from '@src/environments/environment';

import { PermissionGuard } from './guards';

if (environment.production) {
  Sentry.init({ dsn: 'https://75a1aef9c8484ef0a27cb9ac4b284152@o162879.ingest.sentry.io/4504139604557824' });
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    PermissionGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CidInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
    { provide: LOCALE_ID, useValue: 'en-gb' },
    MatIconRegistry,
  ],
})
export class CoreModule {}
