import { NgModule } from '@angular/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthModule } from '@auth0/auth0-angular';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { CoreModule } from '@core/core.module';

import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRouting,
    BrowserAnimationsModule,
    CoreModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    MatSnackBarModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: `https://${environment.auth0.audience}/api/v2/`,
      },
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [`${environment.apiHost}/auth/userinfo`, `${environment.apiHost}/v1/*`],
      },
    }),
    GoogleTagManagerModule.forRoot({ id: environment.gtm }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
